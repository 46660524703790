import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import eventosApi from '../../utils/api/eventosApi';

export const createEventoRequest = createAction('CREATE_EVENTO_REQUEST');
export const createEventoSuccess = createAction('CREATE_EVENTO_SUCCESS');
export const createEventoError = createAction('CREATE_EVENTO_ERROR');

export const getEventoRequest = createAction('GET_EVENTO_REQUEST');
export const getEventoSuccess = createAction('GET_EVENTO_SUCCESS');
export const getEventoError = createAction('GET_EVENTO_ERROR');

export const updateEventoRequest = createAction('UPDATE_EVENTO_REQUEST');
export const updateEventoSuccess = createAction('UPDATE_EVENTO_SUCCESS');
export const updateEventoError = createAction('UPDATE_EVENTO_ERROR');

export const deleteEventoRequest = createAction('DELETE_EVENTO_REQUEST');
export const deleteEventoSuccess = createAction('DELETE_EVENTO_SUCCESS');
export const deleteEventoError = createAction('DELETE_EVENTO_ERROR');

export const fetchEventosRequest = createAction('FETCH_EVENTOS_REQUEST');
export const fetchEventosSuccess = createAction('FETCH_EVENTOS_SUCCESS');
export const fetchEventosError = createAction('FETCH_EVENTOS_ERROR');

export const fetchSearchEventosRequest = createAction(
  'FETCH_SEARCH_EVENTOS_REQUEST'
);
export const fetchSearchEventosSuccess = createAction(
  'FETCH_SEARCH_EVENTOS_SUCCESS'
);
export const fetchSearchEventosError = createAction(
  'FETCH_SEARCH_EVENTOS_ERROR'
);

export const newEventoRequest = createAction('NEW_EVENTO_REQUEST');

export const publicarEventoRequest = createAction('PUBLICAR_EVENTO_REQUEST');
export const publicarEventoSuccess = createAction('PUBLICAR_EVENTO_SUCCESS');
export const publicarEventoError = createAction('PUBLICAR_EVENTO_ERROR');

export const cancelarEventoRequest = createAction('CANCELAR_EVENTO_REQUEST');
export const cancelarEventoSuccess = createAction('CANCELAR_EVENTO_SUCCESS');
export const cancelarEventoError = createAction('CANCELAR_EVENTO_ERROR');

export const encerrarEventoRequest = createAction('ENCERRAR_EVENTO_REQUEST');
export const encerrarEventoSuccess = createAction('ENCERRAR_EVENTO_SUCCESS');
export const encerrarEventoError = createAction('ENCERRAR_EVENTO_ERROR');

export const getStatsEventosRequest = createAction('GET_STATS_EVENTOS_REQUEST');
export const getStatsEventosSuccess = createAction('GET_STATS_EVENTOS_SUCCESS');
export const getStatsEventosError = createAction('GET_STATS_EVENTOS_ERROR');

export const getRelatorioEventoRequest = createAction(
  'GET_RELATORIO_EVENTO_REQUEST'
);
export const getRelatorioEventoSuccess = createAction(
  'GET_RELATORIO_EVENTO_SUCCESS'
);
export const getRelatorioEventoError = createAction(
  'GET_RELATORIO_EVENTO_ERROR'
);

export const fetchEventos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchEventosRequest());
    const { data } = await eventosApi.listEventos(page, pageSize);
    dispatch(fetchEventosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchEventosError(e));
    dispatch(displayError(e));
  }
};

export const getEvento = (id) => async (dispatch) => {
  try {
    dispatch(getEventoRequest());
    const { data } = await eventosApi.readEvento(id);
    dispatch(getEventoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getEventoError(e));
    dispatch(displayError(e));
  }
};

export const createEvento = (form) => async (dispatch) => {
  try {
    dispatch(createEventoRequest());
    const { data } = await eventosApi.createEvento(form);
    dispatch(displaySuccess('Evento incluído com sucesso!'));
    dispatch(createEventoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createEventoError(e));
    dispatch(displayError(e));
  }
};

export const updateEvento = (id, form) => async (dispatch) => {
  try {
    dispatch(updateEventoRequest());
    const { data } = await eventosApi.updateEvento(id, form);
    dispatch(displaySuccess('Evento atualizado com sucesso!'));
    dispatch(updateEventoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateEventoError(e));
    dispatch(displayError(e));
  }
};

export const deleteEvento = (id) => async (dispatch) => {
  try {
    dispatch(deleteEventoRequest());
    const { data } = await eventosApi.deleteEvento(id);
    dispatch(deleteEventoSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteEventoError(e));
    dispatch(displayError(e));
  }
};

export const newEvento = () => async (dispatch) => {
  dispatch(newEventoRequest());
};

export const fetchSearchEventos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchEventosRequest());
      const { data } = await eventosApi.searchEventos(page, pageSize, search);
      dispatch(fetchSearchEventosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchEventosError(e));
      dispatch(displayError(e));
    }
  };

export const publicarEvento = (id) => async (dispatch) => {
  try {
    dispatch(publicarEventoRequest());
    const { data } = await eventosApi.publicarEvento(id);
    dispatch(publicarEventoSuccess(data));
    dispatch(displaySuccess('Evento publicado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(publicarEventoError(e));
    dispatch(displayError(e));
  }
};

export const cancelarEvento = (id) => async (dispatch) => {
  try {
    dispatch(cancelarEventoRequest());
    const { data } = await eventosApi.cancelarEvento(id);
    dispatch(cancelarEventoSuccess(data));
    dispatch(displaySuccess('Evento cancelado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(cancelarEventoError(e));
    dispatch(displayError(e));
  }
};

export const encerrarEvento = (id) => async (dispatch) => {
  try {
    dispatch(encerrarEventoRequest());
    const { data } = await eventosApi.encerrarEvento(id);
    dispatch(encerrarEventoSuccess(data));
    dispatch(displaySuccess('Evento encerrado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(encerrarEventoError(e));
    dispatch(displayError(e));
  }
};

export const getStatsEventos = () => async (dispatch) => {
  try {
    dispatch(getStatsEventosRequest());
    const { data } = await eventosApi.getStatsEventos();
    dispatch(getStatsEventosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getStatsEventosError(e));
    dispatch(displayError(e));
  }
};

export const getRelatorioEvento = (id) => async (dispatch) => {
  try {
    dispatch(getRelatorioEventoRequest());
    const { data } = await eventosApi.getRelatorioEvento(id);
    dispatch(getRelatorioEventoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getRelatorioEventoError(e));
    dispatch(displayError(e));
  }
};
