import fileSaver from 'file-saver';
import { handleActions } from 'redux-actions';
import {
  createEventoRequest,
  createEventoSuccess,
  createEventoError,
  getEventoRequest,
  getEventoSuccess,
  getEventoError,
  updateEventoRequest,
  updateEventoSuccess,
  updateEventoError,
  deleteEventoRequest,
  deleteEventoSuccess,
  deleteEventoError,
  fetchEventosRequest,
  fetchEventosSuccess,
  fetchEventosError,
  fetchSearchEventosRequest,
  fetchSearchEventosSuccess,
  fetchSearchEventosError,
  newEventoRequest,
  publicarEventoRequest,
  publicarEventoSuccess,
  publicarEventoError,
  cancelarEventoRequest,
  cancelarEventoSuccess,
  cancelarEventoError,
  encerrarEventoRequest,
  encerrarEventoSuccess,
  encerrarEventoError,
  getStatsEventosRequest,
  getStatsEventosSuccess,
  getStatsEventosError,
  getRelatorioEventoRequest,
  getRelatorioEventoSuccess,
  getRelatorioEventoError,
} from '../actions/eventoActions';

const defaultState = {
  eventos: [],
  totalPages: 0,
  evento: {},
  stats: {},
  count: 0,
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [fetchEventosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchEventosSuccess](state, { payload }) {
      return {
        ...state,
        eventos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchEventosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchEventosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchEventosSuccess](state, { payload }) {
      return {
        ...state,
        eventos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchEventosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getEventoRequest](state) {
      return {
        ...state,
        evento: {},
        isFetching: true,
        error: null,
      };
    },
    [getEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isFetching: false,
        error: null,
      };
    },
    [getEventoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteEventoSuccess](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newEventoRequest](state, { payload }) {
      return {
        ...state,
        evento: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
    [publicarEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [publicarEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isUpdating: false,
        error: null,
      };
    },
    [publicarEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [cancelarEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [cancelarEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isUpdating: false,
        error: null,
      };
    },
    [cancelarEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [encerrarEventoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [encerrarEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isUpdating: false,
        error: null,
      };
    },
    [encerrarEventoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [getStatsEventosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getStatsEventosSuccess](state, { payload }) {
      return {
        ...state,
        stats: payload,
        isFetching: false,
        error: null,
      };
    },
    [getStatsEventosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getRelatorioEventoRequest](state) {
      return {
        ...state,
        evento: {},
        isFetching: true,
        error: null,
      };
    },
    [getRelatorioEventoSuccess](state, { payload }) {
      fileSaver.saveAs(
        new Blob([payload], { type: 'application/octet-stream' }),
        `relatorio-evento-${new Date().getTime()}.xlsx`
      );
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [getRelatorioEventoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
