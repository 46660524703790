import { Field } from 'react-final-form';

import renderAsyncSelectField from '@/shared/components/form/AsyncSelect';
import usuariosApi from '../../utils/api/usuariosApi';

const DropDownUsuariosSelect = () => {
  async function funcao(search) {
    const searchForm = {
      sortBy: 'nome',
    };
    if (search) searchForm.search = search;
    try {
      const { data } = await usuariosApi.searchUsuarios(1, 10, searchForm);
      return [{ value: undefined, label: 'Todos' }].concat(
        data.docs.map((usuario) => ({
          value: usuario._id,
          label: usuario.nome,
        }))
      );
    } catch (e) {
      console.log(e);
      return [{ value: undefined, label: 'Todos' }];
    }
  }

  return (
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="usuariosSelect"
          className="react-select-usuario"
          component={renderAsyncSelectField}
          type="text"
          defaultOptions
          placeholder="Cliente"
          loadOptions={funcao}
        />
      </div>
    </div>
  );
};
export default DropDownUsuariosSelect;
