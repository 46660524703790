import { handleActions } from 'redux-actions';
import {
  fetchPagamentosRequest,
  fetchPagamentosSuccess,
} from '../actions/pagamentoActions';

const defaultState = {
  pagamentos: [],
  totalPages: 0,
  pagamento: {},
  stats: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchPagamentosRequest](state) {
      return {
        ...state,
        pagamentos: [],
        isFetching: true,
        error: null,
      };
    },
    [fetchPagamentosSuccess](state, { payload }) {
      return {
        ...state,
        pagamentos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
