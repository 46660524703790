import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import ingressosApi from '../../utils/api/ingressosApi';

// export const fetchVendasRequest = createAction('FETCH_VENDAS_REQUEST');
// export const fetchVendasSuccess = createAction('FETCH_VENDAS_SUCCESS');
export const fetchIngressosRequest = createAction('FETCH_INGRESSOS_REQUEST');
export const fetchIngressosSuccess = createAction('FETCH_INGRESSOS_SUCCESS');

export const getIngressoRequest = createAction('GET_INGRESSO_REQUEST');
export const getIngressoSuccess = createAction('GET_INGRESSO_SUCCESS');

export const fetchSearchIngressosRequest = createAction(
  'FETCH_SEARCH_INGRESSOS_REQUEST'
);
export const fetchSearchIngressossSuccess = createAction(
  'FETCH_SEARCH_INGRESSOS_SUCCESS'
);

export const putCheckinSuccess = createAction('PUT_CHECKIN_SUCCESS');
export const putCheckinRequest = createAction('PUT_CHECKIN_REQUEST');

export const putCheckoutSuccess = createAction('PUT_CHECKOUT_SUCCESS');
export const putCheckoutRequest = createAction('PUT_CHECKOUT_REQUEST');

export const putReativarSuccess = createAction('PUT_REATIVAR_SUCCESS');
export const putReativarRequest = createAction('PUT_REATIVAR_REQUEST');

export const cancelarIngressoRequest = createAction(
  'CANCELAR_INGRESSO_REQUEST'
);
export const cancelarIngressoSuccess = createAction(
  'CANCELAR_INGRESSO_SUCCESS'
);

export const fetchIngressos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchIngressosRequest());
    const { data } = await ingressosApi.fetchIngressos(page, pageSize);
    dispatch(fetchIngressosSuccess(data));
  } catch (e) {
    dispatch(displayError(e));
  }
};

export const fetchSearchIngressos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchIngressosRequest());
      const { data } = await ingressosApi.searchIngressos(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchIngressossSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };

export const getIngresso = (id) => async (dispatch) => {
  try {
    dispatch(getIngressoRequest());
    const { data } = await ingressosApi.getIngresso(id);
    dispatch(getIngressoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const reenviarIngresso = (id) => async (dispatch) => {
  try {
    dispatch(getIngressoRequest());
    const { data } = await ingressosApi.reenviarIngresso(id);
    dispatch(getIngressoSuccess(data));
    dispatch(displaySuccess('Ingresso reenviado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const putCheckinIngresso = (id) => async (dispatch) => {
  try {
    dispatch(putCheckinRequest());
    const { data } = await ingressosApi.putCheckinIngresso(id);
    dispatch(putCheckinSuccess(data));
    dispatch(displaySuccess('Checkin feito com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const putCheckoutIngresso = (id) => async (dispatch) => {
  try {
    dispatch(putCheckoutRequest());
    const { data } = await ingressosApi.putCheckoutIngresso(id);
    dispatch(putCheckoutSuccess(data));
    dispatch(displaySuccess('Checkin feito com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const putReativarIngresso = (id) => async (dispatch) => {
  try {
    dispatch(putReativarRequest());
    const { data } = await ingressosApi.putReativarIngresso(id);
    dispatch(putReativarSuccess(data));
    dispatch(displaySuccess('Ingresso reativado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const cancelarIngresso = (id) => async (dispatch) => {
  try {
    dispatch(cancelarIngressoRequest());
    const { data } = await ingressosApi.cancelarIngresso(id);
    dispatch(cancelarIngressoSuccess(data));
    dispatch(displaySuccess('Ingresso cancelado com Sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};
