import { handleActions } from 'redux-actions';
import {
  getPedidoRequest,
  getPedidoSuccess,
  fetchPedidosRequest,
  fetchPedidosSuccess,
  fetchSearchPedidosRequest,
  fetchSearchPedidosSuccess,
  getStatsPedidosRequest,
  getStatsPedidosSuccess,
  getStatsPedidosError,
} from '../actions/pedidoActions';

const defaultState = {
  pedidos: [],
  totalPages: 0,
  pedido: {},
  stats: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getPedidoRequest](state) {
      return {
        ...state,
        pedido: {},
        isFetching: true,
        error: null,
      };
    },
    [getPedidoSuccess](state, { payload }) {
      return {
        ...state,
        pedido: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPedidosRequest](state) {
      return {
        ...state,
        pedidos: [],
        isFetching: true,
        error: null,
      };
    },
    [fetchPedidosSuccess](state, { payload }) {
      return {
        ...state,
        pedidos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchPedidosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchPedidosSuccess](state, { payload }) {
      return {
        ...state,
        pedidos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [getStatsPedidosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getStatsPedidosSuccess](state, { payload }) {
      return {
        ...state,
        stats: payload,
        isFetching: false,
        error: null,
      };
    },
    [getStatsPedidosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
