import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getConfiguracao,
  updateConfiguracao,
} from '@/redux/actions/configuracaoActions';

const ConfiguracaoLojaForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const configuracao = useSelector((state) => state.configuracao.configuracao);
  const isFetching = useSelector((state) => state.configuracao.isFetching);
  const error = useSelector((state) => state.configuracao.error);

  useEffect(() => {
    dispatch(getConfiguracao());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(updateConfiguracao(data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Col md={6} lg={9} xl={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Editar Configurações da Loja</h5>
          </div>

          {isFetching && <Spinner className="table-fetch-spinner" />}
          {configuracao && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={configuracao}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Título</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.titulo"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Título da loja/site"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Descrição</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.descricao"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Descrição da loja/site"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Endereço</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.endereco"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Endereço físico da loja/site"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Telefone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.telefone"
                        type="text"
                        component={FormField}
                        placeholder="(61) 99999-9999"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Url</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.url"
                        type="url"
                        component={FormField}
                        placeholder="www.meusite.com"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.email"
                        type="email"
                        component={FormField}
                        placeholder="meu@email.com"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Instagram</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.instagram"
                        type="text"
                        component={FormField}
                        placeholder="https://instagram.com/SUA@"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Facebook</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.facebook"
                        type="text"
                        component={FormField}
                        placeholder="https://facebook.com.br/sua_pagina"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Whatsapp</span>
                    <div className="form__form-group-field">
                      <Field
                        name="loja.whatsapp"
                        type="text"
                        component={FormField}
                        placeholder="(61) 99999-9999"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ConfiguracaoLojaForm;
