import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import EyeIcon from 'mdi-react/EyeIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Panel from '@/shared/components/Panel';
import moment from 'moment';

const NewOrderAmount = ({ quantity }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': quantity <= 100,
    'dashboard__table-orders-amount--low': quantity <= 20,
  });
  if (quantity > 150) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <span>{quantity}</span>
      </div>
    );
  }
  if (quantity > 100) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <span>{quantity}</span>
      </div>
    );
  }
  if (quantity > 50) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <span>{quantity}</span>
      </div>
    );
  }
  if (quantity > 20) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <span>{quantity}</span>
      </div>
    );
  }
  return (
    <div className={amountClass}>
      <div />
      <span>{quantity}</span>
    </div>
  );
};

NewOrderAmount.propTypes = {
  quantity: PropTypes.number,
};

NewOrderAmount.defaultProps = {
  quantity: 0,
};

const IngressosPorEvento = ({ eventos }) => {
  return (
    <Panel
      xl={6}
      lg={12}
      md={12}
      title={'Ingressos vendidos por evento'}
      subhead="Ingressos emitidos para os próximos 7 eventos"
    >
      <Table responsive striped className="dashboard__table-orders">
        <thead>
          <tr>
            <th>Evento</th>
            <th>Data</th>
            <th>Ingressos</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {eventos &&
            eventos.map((evento, index) => (
              <tr key={index}>
                <td>{evento.nome}</td>
                <td>{moment(evento.data).format('DD/MM/YY')}</td>
                <td>
                  <NewOrderAmount quantity={evento.ingressosVendidos} />
                </td>
                <td>
                  <Link
                    color="primary"
                    className="icon-btn success"
                    type="button"
                    to={`/evento/editar/${evento._id}`}
                  >
                    <EyeIcon />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Link to="/eventos" className="dashboard__table-orders-link">
        Todos os eventos <ChevronDownIcon />
      </Link>
    </Panel>
  );
};

export default IngressosPorEvento;
