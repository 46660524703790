import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UsuariosList from './components/UsuariosList';

const Usuarios = ({ rtl }) => {
  return (
    <Container className="eventos">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Clientes</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize o cadastro de clientes na plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <UsuariosList />
      </Row>
    </Container>
  );
};

export default Usuarios;
