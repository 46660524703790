import { createAction } from 'redux-actions';
import { displayError } from './notificacoesActions';
import pedidosApi from '../../utils/api/pedidosApi';

export const fetchPedidosRequest = createAction('FETCH_PEDIDOS_REQUEST');
export const fetchPedidosSuccess = createAction('FETCH_PEDIDOS_SUCCESS');
export const getPedidoRequest = createAction('GET_PEDIDO_REQUEST');
export const getPedidoSuccess = createAction('GET_PEDIDO_SUCCESS');

export const fetchSearchPedidosRequest = createAction(
  'FETCH_SEARCH_PEDIDOS_REQUEST'
);
export const fetchSearchPedidosSuccess = createAction(
  'FETCH_SEARCH_PEDIDOS_SUCCESS'
);

export const getStatsPedidosRequest = createAction('GET_STATS_PEDIDOS_REQUEST');
export const getStatsPedidosSuccess = createAction('GET_STATS_PEDIDOS_SUCCESS');
export const getStatsPedidosError = createAction('GET_STATS_PEDIDOS_ERROR');

export const fetchPedidos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchPedidosRequest());
    const { data } = await pedidosApi.fetchPedidos(page, pageSize);
    dispatch(fetchPedidosSuccess(data));
  } catch (e) {
    dispatch(displayError(e));
  }
};

export const fetchSearchPedidos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchPedidosRequest());
      const { data } = await pedidosApi.searchPedidos(page, pageSize, search);
      dispatch(fetchSearchPedidosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };
export const getPedido = (id) => async (dispatch) => {
  try {
    dispatch(getPedidoRequest());
    const { data } = await pedidosApi.getPedido(id);
    dispatch(getPedidoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const getStatsPedidos = () => async (dispatch) => {
  try {
    dispatch(getStatsPedidosRequest());
    const { data } = await pedidosApi.getStatsPedidos();
    dispatch(getStatsPedidosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getStatsPedidosError(e));
    dispatch(displayError(e));
  }
};
