/* eslint-disable */
const validate = (values) => {
  const errors = { loja: {} };
  if (!values.loja?.titulo) {
    errors.loja.titulo = 'O campo Título não pode ser vazio';
  }
  if (!values.loja?.url) {
    errors.loja.url = 'O campo URL não pode ser vazio';
  }
  if (!values.loja?.email) {
    errors.loja.email = 'O campo Email não pode ser vazio';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.loja.email)
  ) {
    errors.loja.email = 'Email inválido';
  }

  return errors;
};

export default validate;
