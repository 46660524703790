import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Spinner } from 'reactstrap';

const EventosQuantidade = ({ quantidade, titulo, icone: Component }) => {
  const isFetching = useSelector((state) => state.eventos.isFetching);

  return (
    <Col md={6} lg={3}>
      <Card>
        <CardBody className="dashboard__card-widget">
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {!isFetching && (
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--accent">
                <p className="mobile-app-widget__total-stat">{quantidade}</p>
                <Component className="dashboard__trend-icon" />
              </div>
              <div className="mobile-app-widget__title">
                <h5>{titulo}</h5>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default EventosQuantidade;
