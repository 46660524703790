/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (!values.dataEventoDatePicker) {
    errors.dataEventoDatePicker = 'O campo Data do Evento não pode estar vazio';
  }
  if (
    values.tipoSelect &&
    values.tipoSelect.value === 'Venda Direta' &&
    !values.dataEncerramentoVendaDatePicker
  ) {
    errors.dataEncerramentoVendaDatePicker =
      'O campo Data de Encerramento da Venda não pode estar vazio';
  }
  if (!values.tipoSelect?.value) {
    errors.tipoSelect = 'O campo Tipo de Evento não pode estar vazio';
  }
  if (
    values.tipoSelect &&
    values.tipoSelect.value === 'Venda Externa' &&
    !values.linkEventoExterno
  ) {
    errors.linkEventoExterno =
      'O campo Link Evento Externo não pode estar vazio';
  }
  if (!values.descricaoCurta) {
    errors.descricaoCurta = 'O campo Descrição Curta não pode estar vazio';
  }
  // if (
  //   values.categoriaIngresso &&
  //   values.categoriaIngresso.length > 0 &&
  //   values.categoriaIngresso.length !==
  //     Array.from(new Set(values.categoriaIngresso.map(JSON.stringify))).map(
  //       JSON.parse
  //     )
  // ) {
  //   errors.categoriaIngresso = [
  //     { nomeSelect: 'A categoria do ingresso não pode estar vazio' },
  //   ];
  // }
  return errors;
};

export default validate;
