import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CuponsList from './components/CuponsList';

const Cupons = ({ rtl }) => {
  return (
    <Container className="eventos">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cupons</h3>
          <h3 className="page-subhead subhead">
            Os cupons podem ser usados para conceder descontos aos clientes. O
            desconto pode ser um valor fixo ou percentual.
          </h3>
        </Col>
      </Row>
      <Row>
        <CuponsList />
      </Row>
    </Container>
  );
};

export default Cupons;
