import { handleActions } from 'redux-actions';
import {
  createUsuarioRequest,
  createUsuarioSuccess,
  getUsuarioRequest,
  getUsuarioSuccess,
  updateUsuarioRequest,
  updateUsuarioSuccess,
  deleteUsuarioRequest,
  deleteUsuarioSuccess,
  fetchUsuariosRequest,
  fetchUsuariosSuccess,
  fetchSearchUsuariosRequest,
  fetchSearchUsuariosSuccess,
  newUsuarioRequest,
  getStatsUsuariosRequest,
  getStatsUsuariosSuccess,
  getStatsUsuariosError,
} from '../actions/usuarioActions';

const defaultState = {
  usuarios: [],
  totalPages: 0,
  usuario: {},
  stats: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchUsuariosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchUsuariosSuccess](state, { payload }) {
      return {
        ...state,
        usuarios: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchUsuariosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchUsuariosSuccess](state, { payload }) {
      return {
        ...state,
        usuarios: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [getUsuarioRequest](state) {
      return {
        ...state,
        usuario: {},
        isFetching: true,
        error: null,
      };
    },
    [getUsuarioSuccess](state, { payload }) {
      return {
        ...state,
        usuario: payload,
        isFetching: false,
        error: null,
      };
    },
    [createUsuarioRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createUsuarioSuccess](state, { payload }) {
      return {
        ...state,
        usuario: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateUsuarioRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateUsuarioSuccess](state, { payload }) {
      return {
        ...state,
        usuario: payload,
        isUpdating: false,
        error: null,
      };
    },
    [deleteUsuarioRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteUsuarioSuccess](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [newUsuarioRequest](state, { payload }) {
      return {
        ...state,
        usuario: '',
        isFetching: false,
        error: payload,
      };
    },
    [getStatsUsuariosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getStatsUsuariosSuccess](state, { payload }) {
      return {
        ...state,
        stats: payload,
        isFetching: false,
        error: null,
      };
    },
    [getStatsUsuariosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
