import { handleActions } from 'redux-actions';
import {
  createCupomRequest,
  createCupomSuccess,
  getCupomRequest,
  getCupomSuccess,
  updateCupomRequest,
  updateCupomSuccess,
  deleteCupomRequest,
  deleteCupomSuccess,
  fetchCuponsRequest,
  fetchCuponsSuccess,
  fetchSearchCuponsRequest,
  fetchSearchCuponsSuccess,
  newCupomRequest,
} from '../actions/cupomActions';

const defaultState = {
  cupons: [],
  totalPages: 0,
  cupom: {},
  count: 0,
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [fetchCuponsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCuponsSuccess](state, { payload }) {
      return {
        ...state,
        cupons: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchCuponsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchCuponsSuccess](state, { payload }) {
      return {
        ...state,
        cupons: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [getCupomRequest](state) {
      return {
        ...state,
        cupom: {},
        isFetching: true,
        error: null,
      };
    },
    [getCupomSuccess](state, { payload }) {
      return {
        ...state,
        cupom: payload,
        isFetching: false,
        error: null,
      };
    },
    [createCupomRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createCupomSuccess](state, { payload }) {
      return {
        ...state,
        cupom: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateCupomRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateCupomSuccess](state, { payload }) {
      return {
        ...state,
        cupom: payload,
        isUpdating: false,
        error: null,
      };
    },
    [deleteCupomRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteCupomSuccess](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [newCupomRequest](state, { payload }) {
      return {
        ...state,
        cupom: '',
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
