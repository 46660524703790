import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Modal,
  Progress,
  Spinner,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getPedido } from '../../../redux/actions/pedidoActions';
import { fetchPagamentos } from '../../../redux/actions/pagamentoActions';
import { Link } from 'react-router-dom';
import { BadgePagamento, BadgePedido } from '../../../shared/components/Badges';
import { priceFormatter, translatePaymentType } from '../../../utils/helpers';
import EyeIcon from 'mdi-react/EyeIcon';
import PagamentoDetalheModal from './PagamentoDetalheModal';

const PedidoDetalhe = ({ props }) => {
  const dispatch = useDispatch();
  const pedido = useSelector((state) => state.pedidos.pedido);
  const pagamentos = useSelector((state) => state.pagamentos.pagamentos);
  const isFetching = useSelector((state) => state.pedidos.isFetching);

  const [pagamento, setPagamento] = useState();
  const [showModal, setShowModal] = useState(false);
  const { id } = props.match.params;
  moment.locale('pt-br');

  useEffect(() => {
    dispatch(getPedido(id));
  }, [dispatch, id]);

  useEffect(() => {
    pedido._id && dispatch(fetchPagamentos(1, 999, { pedido: pedido._id }));
  }, [dispatch, pedido]);

  const handleModal = (pag) => () => {
    setPagamento(pag);
    setShowModal(true);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <Fragment>
      {isFetching && <Spinner className="loading-spinner" />}
      {pedido && !isFetching && (
        <Fragment>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th className="pb-2">Número do pedido:</th>
                      <td className="pb-2 pl-2">#{pedido?._id}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Situação:</th>
                      <td className="pb-2 pl-2">
                        <BadgePedido status={pedido.status} />
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Data:</th>
                      <td className="pb-2 pl-2">
                        {moment(pedido?.createdAt).format('ll LT')}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Cliente:</th>
                      <td className="pb-2 pl-2">{pedido?.usuario?.nome}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Email:</th>
                      <td className="pb-2 pl-2">{pedido?.usuario?.email}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Código do cupom:</th>
                      <td className="pb-2 pl-2">{pedido?.cupom?.codigo}</td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Ações</h5>
                </div>
                <ButtonToolbar>
                  <Link to="#" className="btn btn-secondary">
                    Cancelar Pedido
                  </Link>
                  <Link to="#" className="btn btn-secondary">
                    Reenviar ingressos
                  </Link>
                  <Link to="#" className="btn btn-secondary">
                    Reembolsar
                  </Link>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Eventos</h5>
                </div>
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Data do evento</th>
                      <th>Tipo de ingresso</th>
                      <th>Quantidade</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedido.itens?.map((item, key) => (
                      <tr key={`item-${key}`}>
                        <td>{item.evento?.nome}</td>
                        <td>
                          {moment(item.evento?.dataInicioEvento).format(
                            'DD/MM/YYYY hh:mm'
                          )}
                        </td>
                        <td>
                          {
                            item?.evento?.categoriaIngresso?.find(
                              (categoria) =>
                                categoria._id === item.categoriaIngresso
                            ).nome
                          }
                        </td>
                        <td>{item?.quantidade}</td>
                        <td>{priceFormatter(item?.valor)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={4}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Resumo</h5>
                </div>
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th className="pb-2">Valor dos Itens (+) </th>
                      <td className="pb-2 pl-3">
                        {priceFormatter(pedido.valorItens)}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Valor de Desconto (-)</th>
                      <td className="pb-2 pl-3">
                        {priceFormatter(pedido.valorDesconto)}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2"> Sub Total (=)</th>
                      <td className="pb-2 pl-3">
                        {priceFormatter(pedido.subTotal)}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Tarifa Conveniência (+)</th>
                      <td className="pb-2 pl-3">
                        {priceFormatter(pedido.tarifaConveniencia)}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Total do Pedido (=)</th>
                      <td className="pb-2 pl-3">
                        {priceFormatter(pedido.totalPedido)}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {pedido?.tentativas?.map((tentativa, index) => (
                      <tr>
                        <td>{moment(tentativa.data).format('ll LT')}</td>
                        <td>
                          <div className="progress-wrap progress-wrap--blue">
                            <Progress
                              value={tentativa.percentual}
                            >{`${tentativa.percentual}%`}</Progress>
                          </div>
                        </td>
                        <td>
                          {tentativa.aprovado ? (
                            <CheckIcon className="icon--success" />
                          ) : (
                            <CloseCircleOutlineIcon className="icon--danger" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={8}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Pagamentos</h5>
                </div>
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Valor</th>
                      <th>Parcelas</th>
                      <th>Forma de Pagamento</th>
                      <th>Situação</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagamentos?.map((pagamento, index) => (
                      <tr>
                        <td>
                          {moment(pagamento?.createdAt).format(
                            'DD/MM/YYYY hh:mm'
                          )}
                        </td>
                        <td>{priceFormatter(pagamento?.valor)}</td>
                        <td>{pagamento?.parcelas}</td>
                        <td>{translatePaymentType(pagamento)}</td>
                        <td>
                          <BadgePagamento status={pagamento?.status} />
                        </td>
                        <td>
                          <button
                            className="icon-btn primary"
                            type="button"
                            id="ver"
                            onClick={handleModal(pagamento)}
                          >
                            <EyeIcon />
                          </button>
                          <UncontrolledTooltip placement="left" target="ver">
                            Informações do Pagamento
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Fragment>
      )}
      <Modal />
      <PagamentoDetalheModal
        isOpen={showModal}
        toggle={toggleModal}
        pagamento={pagamento}
      />
    </Fragment>
  );
};

export default PedidoDetalhe;
