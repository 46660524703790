import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddIcon from 'mdi-react/AddIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import renderSelectField from '@/shared/components/form/Select';
import renderDatepickerField from '@/shared/components/form/DatepickerField';
import renderDropZoneField from '@/shared/components/form/DropZone';
import FormField, {
  renderMaskedField,
} from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getEvento,
  createEvento,
  updateEvento,
  newEvento,
} from '@/redux/actions/eventoActions';
import { listaTipoEvento } from '../../../utils/helpers';

const EventoForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const evento = useSelector((state) => state.eventos.evento);
  const isFetching = useSelector((state) => state.eventos.isFetching);
  const isUpdating = useSelector((state) => state.eventos.isUpdating);
  const error = useSelector((state) => state.eventos.error);
  const [removerImagem, setRemoverImagem] = useState(false);
  const [isEventoExterno, setIsEventoExterno] = useState(false);
  const formRef = useRef({});
  const { id } = props.match.params;
  const isAddMode = !id;
  const modeloCategoriaIngresso = {
    nome: '',
    valor: '',
    quantidade: 0,
    dataInicio: '',
    dataFim: '',
    lote: '',
  };
  const numberMask = createNumberMask({
    prefix: 'R$ ',
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    allowLeadingZeroes: true,
  });

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getEvento(id));
    } else {
      dispatch(newEvento());
    }
  }, [dispatch, id, isAddMode]);

  useEffect(() => {
    evento.urlImagens = [];
    setRemoverImagem(false);
  }, [removerImagem]);

  if (evento) {
    evento.tipoSelect = { value: evento.tipo, label: evento.tipo };
    evento.dataEventoDatePicker = moment(evento.dataInicioEvento).toDate();
    if (evento.dataEncerramentoVenda) {
      evento.dataEncerramentoVendaDatePicker = moment(
        evento.dataEncerramentoVenda
      ).toDate();
    }
    for (let categoria in evento.categoriaIngresso) {
      evento.categoriaIngresso[categoria].nomeSelect = {
        value: evento.categoriaIngresso[categoria].nome,
        label: evento.categoriaIngresso[categoria].nome,
      };
    }
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    const fields = Object.keys(data).map((key, i) => key);
    fields.forEach((item) => {
      if (item !== 'file' && item !== 'categoriaIngresso')
        formData.set(item, data[item]);
    });
    if (data.files && data.files.length > 0) {
      formData.append('file', data.files[0]);
    } else {
      formData.set('urlImagens', evento.urlImagens);
    }
    if (!isEventoExterno) {
      formData.set('linkEventoExterno', '');
    }
    if (data.categoriaIngresso) {
      formData.set(
        'categoriaIngresso',
        JSON.stringify(
          data.categoriaIngresso.map((categoria) => ({
            ...categoria,
            valor: `${categoria.valor}`
              .replace('R$ ', '')
              .replace('.', '')
              .replace(',', '.'),
          }))
        )
      );
    }
    formData.set('tipo', data.tipoSelect.value);
    formData.set('dataInicioEvento', data.dataEventoDatePicker);
    if (!isEventoExterno) {
      formData.set(
        'dataEncerramentoVenda',
        data.dataEncerramentoVendaDatePicker
      );
    }
    // return;
    isAddMode
      ? dispatch(createEvento(formData))
      : dispatch(updateEvento(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/eventos" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{isAddMode ? 'Novo' : 'Editar'} Evento</h5>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(evento || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : evento}
            mutators={{
              ...arrayMutators,
            }}
          >
            {({
              values,
              handleSubmit,
              form,
              form: {
                reset,
                pristine,
                submitting,
                mutators: { push, remove },
              },
            }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={(props) => {
                      props.values.tipoSelect &&
                        formRef.current.change(
                          'tipo',
                          setIsEventoExterno(
                            props.values.tipoSelect.value === 'Venda Externa'
                          )
                        );
                      for (let categoria in props.values.categoriaIngresso) {
                        if (
                          props.values.categoriaIngresso[categoria].nomeSelect
                        ) {
                          props.values.categoriaIngresso[categoria].nome =
                            props.values.categoriaIngresso[
                              categoria
                            ].nomeSelect?.value;
                        }
                      }
                    }}
                  />
                  <Col lg={6} className="pl-0">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Nome</span>
                      <div className="form__form-group-field">
                        <Field
                          name="nome"
                          type="text"
                          component={FormField}
                          rules={{ required: 'Campo Obrigatório' }}
                          isAboveError={isAboveError}
                          placeholder="Nome do Evento"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Tipo</span>
                      <div className="form__form-group-field">
                        <Field
                          name="tipoSelect"
                          component={renderSelectField}
                          type="text"
                          options={listaTipoEvento}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Data do Evento
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          component={renderDatepickerField}
                          name="dataEventoDatePicker"
                          timeCaption="Horário"
                          dateFormat="dd/MM/yyyy – HH:mm"
                          placeholderText="dd/mm/aaaa – hh:mm"
                          showTimeInput
                          isClearable
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    {!isEventoExterno && (
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Data do Encerramento da Venda
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            component={renderDatepickerField}
                            name="dataEncerramentoVendaDatePicker"
                            timeCaption="Horário"
                            dateFormat="dd/MM/yyyy – HH:mm"
                            placeholderText="dd/mm/aaaa – hh:mm"
                            showTimeInput
                            isClearable
                            isAboveError={isAboveError}
                          />
                        </div>
                      </div>
                    )}
                    {isEventoExterno && (
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Link Evento Externo
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="linkEventoExterno"
                            type="text"
                            component={FormField}
                            isAboveError={isAboveError}
                            placeholder="https://wwww.google.com"
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col lg={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Imagem de Capa do Evento
                      </span>
                      {(!evento.urlImagens ||
                        evento.urlImagens?.length === 0) && (
                        <Field name="files" component={renderDropZoneField} />
                      )}
                      {evento.urlImagens &&
                        evento.urlImagens?.length > 0 &&
                        evento.urlImagens.map((imagem, key) => (
                          <div
                            key={`div-${key}`}
                            className="evento__imagem_container"
                          >
                            <div className="evento__imagem_botao">
                              <Button
                                color="danger"
                                key={`btn-${key}`}
                                className="btn-sm"
                                onClick={(e) => {
                                  setRemoverImagem(true);
                                }}
                              >
                                <TrashIcon /> Remover
                              </Button>
                            </div>
                            <img
                              src={imagem}
                              key={`img-${key}`}
                              alt="capa-evento"
                            />
                          </div>
                        ))}
                    </div>
                  </Col>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Descrição Curta
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="descricaoCurta"
                        type="text"
                        component={FormField}
                        placeholder="Descrição curta do evento..."
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Descrição Completa
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="descricaoCompleta"
                        type="textarea"
                        component="textarea"
                        placeholder="Descrição completa do evento..."
                      />
                    </div>
                  </div>
                  {!isEventoExterno && (
                    <>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Aviso Impresso no Ingresso
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="avisoIngresso"
                            type="textarea"
                            component="textarea"
                            placeholder="Aviso para o corpo do ingresso..."
                          />
                        </div>
                      </div>
                      <h4 className="bold-text mb-4">
                        Categorias de ingressos
                      </h4>
                      <FieldArray name="categoriaIngresso">
                        {({ fields }) =>
                          fields.map((categoria, indexPergunta) => (
                            <div className="form__form-group-categoria-ingresso">
                              <div className="form__form-group-categoria-nome">
                                <span className="form__form-group-label">
                                  Nome
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name={`${categoria}.nomeSelect`}
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                      { value: 'Meia', label: 'Meia' },
                                      { value: 'Inteira', label: 'Inteira' },
                                      {
                                        value: 'Duplo Meia',
                                        label: 'Duplo Meia',
                                      },
                                      { value: 'Duplo', label: 'Duplo' },
                                      {
                                        value: 'Pista Meia',
                                        label: 'Pista Meia',
                                      },
                                      {
                                        value: 'Pista Inteira',
                                        label: 'Pista Inteira',
                                      },
                                      {
                                        value: 'Frontstage Meia',
                                        label: 'Frontstage Meia',
                                      },
                                      {
                                        value: 'Frontstage Inteira',
                                        label: 'Frontstage Inteira',
                                      },
                                      {
                                        value: 'Camarote Meia',
                                        label: 'Camarote Meia',
                                      },
                                      {
                                        value: 'Camarote Inteira',
                                        label: 'Camarote Inteira',
                                      },
                                      {
                                        value: 'Mesa Meia',
                                        label: 'Mesa Meia',
                                      },
                                      {
                                        value: 'Mesa Inteira',
                                        label: 'Mesa Inteira',
                                      },
                                    ]}
                                    isAboveError={isAboveError}
                                  />
                                </div>
                              </div>
                              <div className="form__form-group-categoria-valor">
                                <span className="form__form-group-label">
                                  Valor
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name={`${categoria}.valor`}
                                    type="text"
                                    component={renderMaskedField}
                                    placeholder="R$ 19,00"
                                    mask={numberMask}
                                    guide={true}
                                    isAboveError={isAboveError}
                                  />
                                </div>
                              </div>
                              <div className="form__form-group-categoria-icone">
                                <button
                                  className="icon-btn danger"
                                  type="button"
                                  onClick={() => {
                                    remove('categoriaIngresso', indexPergunta);
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <div className="form__form-group text-right">
                        <Button
                          size="sm"
                          color="success"
                          onClick={() => {
                            push('categoriaIngresso', modeloCategoriaIngresso);
                          }}
                        >
                          <AddIcon /> Categoria ingresso
                        </Button>
                      </div>
                    </>
                  )}
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

EventoForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

EventoForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default EventoForm;
