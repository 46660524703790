import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CheckinsList from './components/CheckinsList';

const Checkins = (props) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Check-in</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os Check-in emitidos pela plataforma, mostrando os mais
          recentes primeiro.
        </h3>
      </Col>
    </Row>
    <Row>
      <CheckinsList isAboveError props={props} />
    </Row>
  </Container>
);

export default Checkins;
