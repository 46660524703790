/* eslint-disable import/no-anonymous-default-export */
import { get, post, put, deleteRequest } from './base/index';
import { getUserId } from '../helpers';

export default {
  createUsuario: (form) => post(`/admin/usuario/create/${getUserId()}`, form),
  readUsuario: (id) => get(`/admin/usuario/${id}/${getUserId()}`),
  updateUsuario: (id, form) => put(`/admin/usuario/${id}/${getUserId()}`, form),
  deleteUsuario: (id) => deleteRequest(`/admin/usuario/${id}/${getUserId()}`),
  listUsuarios: (page, pageSize) =>
    get(`/admin/usuarios/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  searchUsuarios: (page, pageSize, searchForm) =>
    post(
      `/admin/usuarios/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
  getStatsUsuarios: () => get(`/admin/usuarios/stats/${getUserId()}`),
};
