import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Progress,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getIngresso } from '../../../redux/actions/ingressoActions';
import {
  reenviarIngresso,
  cancelarIngresso,
} from '../../../redux/actions/ingressoActions';
import {
  BadgeEvento,
  BadgeIngresso,
  BadgePagamento,
} from '../../../shared/components/Badges';
import { priceFormatter } from '../../../utils/helpers';

const IngressoDetalhe = ({ props }) => {
  const dispatch = useDispatch();
  const ingresso = useSelector((state) => state.ingressos.ingresso);
  const isFetching = useSelector((state) => state.ingressos.isFetching);
  const { id } = props.match.params;
  moment.locale('pt-br');

  useEffect(() => {
    dispatch(getIngresso(id));
  }, [dispatch, id]);

  const handleReenviarIngresso = (id) => {
    dispatch(reenviarIngresso(id));
  };

  const handleCancelarIngresso = (id) => {
    dispatch(cancelarIngresso(id));
  };

  return (
    <Fragment>
      {isFetching && <Spinner className="loading-spinner" />}
      {ingresso && !isFetching && (
        <Fragment>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th className="pb-2">Número do ingresso:</th>
                      <td className="pb-2 pl-2">#{ingresso?._id}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Data da emissão:</th>
                      <td className="pb-2 pl-2">
                        {moment(ingresso?.createdAt).format('ll LT')}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Convidado:</th>
                      <td className="pb-2 pl-2">{ingresso?.titular?.nome}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Email do convidado:</th>
                      <td className="pb-2 pl-2">{ingresso?.titular?.email}</td>
                    </tr>
                    <tr>
                      <th>Situação: </th>
                      <td>
                        <BadgeIngresso status={ingresso.status} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Ações</h5>
                </div>
                <ButtonToolbar>
                  {ingresso.status !== 'cancelado' && (
                    <Button
                      className="btn btn-secondary"
                      to={`#`} //ingresso/${ingresso._id}
                      onClick={() => handleReenviarIngresso(ingresso._id)}
                    >
                      Reenviar ingresso
                    </Button>
                  )}
                  {ingresso.status !== 'cancelado' && (
                    <Button
                      color="danger"
                      className="btn-sm"
                      to={`#`} //ingresso/cancelar/:ingressoId/:adminId
                      onClick={() => handleCancelarIngresso(ingresso._id)}
                    >
                      Cancelar Ingresso
                    </Button>
                  )}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Evento</h5>
                </div>
                <table className="project-summary__info" responsive>
                  <thead>
                    <tr>
                      <th className="pb-2">Nome do evento: </th>
                      <td className="pb-2 pl-2">{ingresso?.evento?.nome}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Data de início: </th>
                      <td className="pb-2 pl-2">
                        {moment(ingresso?.evento?.dataInicioEvento).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Situação: </th>
                      <td className="pb-2 pl-2">
                        <BadgeEvento status={ingresso?.evento?.status} />
                      </td>
                    </tr>
                  </thead>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Pedido</h5>
                </div>
                <table className="project-summary__info" responsive>
                  <thead>
                    <tr>
                      <th className="pb-2">Número do pedido:</th>
                      <td className="pb-2 pl-2">#{ingresso?.pedido?._id}</td>
                    </tr>
                    <tr>
                      <th className="pb-2">Cliente:</th>
                      <td className="pb-2 pl-2">
                        {ingresso?.pedido?.usuario?.nome}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Email:</th>
                      <td className="pb-2 pl-2">
                        {ingresso?.pedido?.usuario?.email}
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Situação:</th>
                      <td className="pb-2 pl-2">
                        <BadgePagamento status={ingresso?.pedido?.status} />
                      </td>
                    </tr>
                    <tr>
                      <th className="pb-2">Valor:</th>
                      <td className="pb-2 pl-2">
                        {priceFormatter(ingresso?.pedido?.totalPedido)}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {ingresso?.tentativas?.map((tentativa, index) => (
                      <tr>
                        <td>{moment(tentativa.data).format('ll LT')}</td>
                        <td>
                          <div className="progress-wrap progress-wrap--blue">
                            <Progress
                              value={tentativa.percentual}
                            >{`${tentativa.percentual}%`}</Progress>
                          </div>
                        </td>
                        <td>
                          {tentativa.aprovado ? (
                            <CheckIcon className="icon--success" />
                          ) : (
                            <CloseCircleOutlineIcon className="icon--danger" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default IngressoDetalhe;
