import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick, sidebarCollapse }) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        icon="home"
        title="Início"
        route="/dashboard"
        onClick={onClick}
      />
      <SidebarLink
        icon="star"
        title="Eventos"
        route="/eventos"
        onClick={onClick}
      />
      <SidebarLink
        icon="users"
        title="Clientes"
        route="/usuarios"
        onClick={onClick}
      />
      <SidebarLink
        icon="license"
        title="Ingressos"
        route="/ingressos"
        onClick={onClick}
      />
      <SidebarLink
        icon="cart"
        title="Pedidos"
        route="/pedidos"
        onClick={onClick}
      />
      <SidebarLink
        icon="tag"
        title="Cupons"
        route="/cupons"
        onClick={onClick}
      />
      <SidebarLink
        icon="checkmark-circle"
        title="Check-in"
        route="/checkin"
        onClick={onClick}
      />
      <SidebarLink
        icon="picture"
        title="Check-in QrCode"
        route="/checkinsqrcode"
        onClick={onClick}
      />
    </ul>
    <ul className="sidebar__block">
      <SidebarCategory
        title="Configurações"
        icon="cog"
        sidebarCollapse={sidebarCollapse}
      >
        <SidebarLink
          title="Configuração da Loja"
          route="/configuracao/loja"
          onClick={onClick}
        />
        <SidebarLink
          title="Eventos/Ingressos"
          route="/configuracao/ingressos"
          onClick={onClick}
        />
        <SidebarLink
          title="Integrações"
          route="/configuracao/integracoes"
          onClick={onClick}
        />
      </SidebarCategory>
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
