/* eslint-disable import/no-anonymous-default-export */
import { get } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  fetchPagamentos: (page, pageSize, search) =>
    get(`${API}/admin/pagamentos/${getUserId()}`, {
      params: { page, pageSize, ...search },
    }),
};
