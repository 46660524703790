import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AddIcon from 'mdi-react/AddIcon';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getConfiguracao,
  updateConfiguracao,
} from '@/redux/actions/configuracaoActions';

const ConfiguracaoIngressoForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const configuracao = useSelector((state) => state.configuracao.configuracao);
  const isFetching = useSelector((state) => state.configuracao.isFetching);
  const error = useSelector((state) => state.configuracao.error);

  useEffect(() => {
    dispatch(getConfiguracao());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(updateConfiguracao(data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Col md={6} lg={9} xl={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Editar Configurações</h5>
          </div>

          {isFetching && <Spinner className="table-fetch-spinner" />}
          {configuracao && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={configuracao}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Título</span>
                    <div className="form__form-group-field">
                      <Button
                        color="secondary"
                        className="icon-btn px-3 py-1"
                        type="button"
                      >
                        <AddIcon />
                        Categoria
                      </Button>
                    </div>
                    {configuracao.ingresso &&
                      configuracao.ingresso.categorias.map((categoria) => (
                        <Badge>{categoria}</Badge>
                      ))}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Tempo para encerramento
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ingresso.tempoEncerramentoVenda"
                        type="number"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="99"
                      />
                    </div>
                    <span className="form__form-group-description">
                      Tempo padrão de antecedência para encerramento da venda de
                      ingressos. Ex. 2hs.
                    </span>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Máx. ingressos por pedido
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ingresso.qtdMaxIngressoPedido"
                        type="number"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="99"
                      />
                    </div>
                    <span className="form__form-group-description">
                      Quantidade máxima de ingressos por pedido.
                    </span>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ConfiguracaoIngressoForm;
