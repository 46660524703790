import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  authReducer,
  notificacoesReducer,
  eventoReducer,
  configuracaoReducer,
  cupomReducer,
  pedidoReducer,
  pagamentoReducer,
  usuarioReducer,
  ingressoReducer,
} from '@/redux/reducers/index';

const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  notificacoes: notificacoesReducer,
  eventos: eventoReducer,
  configuracao: configuracaoReducer,
  cupons: cupomReducer,
  pedidos: pedidoReducer,
  pagamentos: pagamentoReducer,
  usuarios: usuarioReducer,
  ingressos: ingressoReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
