import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

const IntervalDatePickerField = ({ onChange }) => {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
  });

  const handleChangeStart = (startDate) => {
    const newState = state;

    newState.startDate = startDate;

    setState({ startDate: newState.startDate, endDate: newState.endDate });
    onChange({ start: newState.startDate, end: newState.endDate });
  };

  const handleChangeEnd = (endDate) => {
    const newState = state;

    newState.endDate = endDate;

    setState({ startDate: newState.startDate, endDate: newState.endDate });
    onChange({ start: newState.startDate, end: newState.endDate });
  };

  const { startDate, endDate } = state;
  return (
    <div className="date-picker date-picker--interval">
      <DatePicker
        isClearable={true}
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeStart}
        dateFormat="dd/MM/yyyy"
        placeholderText="De"
        dropDownMode="select"
        withPortal={isMobileOnly}
      />
      <MinusIcon className="date-picker__svg" />
      <DatePicker
        isClearable={true}
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeEnd}
        dateFormat="dd/MM/yyyy"
        placeholderText="Até"
        dropDownMode="select"
        withPortal={isMobileOnly}
      />
    </div>
  );
};

IntervalDatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default renderComponentField(IntervalDatePickerField);
