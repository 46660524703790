import React from 'react';
import { Badge } from 'reactstrap';

const BadgeEventoColor = {
  agendado: 'secondary',
  ativo: 'success',
  inativo: 'danger',
  'venda encerrada': 'warning',
};

const BadgeEvento = ({ status }) => (
  <Badge color={BadgeEventoColor[status]}>{status}</Badge>
);

const BadgeCupomColor = {
  valor: 'warning',
  percentual: 'success',
};

const BadgeCupom = ({ tipoDesconto }) => (
  <Badge color={BadgeCupomColor[tipoDesconto]}>{tipoDesconto}</Badge>
);

const BadgePagamentoColor = {
  Pendente: 'secondary',
  Aguardando: 'warning',
  Pago: 'success',
  Falhou: 'danger',
  Devolvido: 'primary',
};

const BadgePagamento = ({ status }) => (
  <Badge color={BadgePagamentoColor[status]}>{status}</Badge>
);

const BadgePedidoColor = {
  Pendente: 'secondary',
  Aguardando: 'warning',
  Pago: 'success',
  Falhou: 'danger',
  Devolvido: 'primary',
};

const BadgePedido = ({ status }) => (
  <Badge color={BadgePedidoColor[status]}>{status}</Badge>
);

const BadgeIngressoColor = {
  'aguardando check-in': 'warning',
  'check-in realizado': 'success',
  'cancelado': 'danger',
};

const BadgeIngresso = ({ status }) => (
  <Badge color={BadgeIngressoColor[status]}>{status}</Badge>
);

const BadgeQrcodeColor = {
  Sucesso: 'success',
  Erro: 'danger',
  'Esperando Qr Code': 'warning'
};

const BadgeQrcode = ({ status }) => (
  <Badge color={BadgeQrcodeColor[status]}>{status}</Badge>
);

export { BadgeEvento, BadgeCupom, BadgePagamento, BadgePedido, BadgeIngresso, BadgeQrcode };
