import { createAction } from 'redux-actions';
import configuracaoApi from '../../utils/api/configuracaoApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const getConfiguracaoRequest = createAction('GET_CONFIGURACAO_REQUEST');
export const getConfiguracaoSuccess = createAction('GET_CONFIGURACAO_SUCCESS');
export const getConfiguracaoError = createAction('GET_CONFIGURACAO_ERROR');

export const updateConfiguracaoRequest = createAction(
  'UPDATE_CONFIGURACAO_REQUEST'
);
export const updateConfiguracaoSuccess = createAction(
  'UPDATE_CONFIGURACAO_SUCCESS'
);
export const updateConfiguracaoError = createAction(
  'UPDATE_CONFIGURACAO_ERROR'
);

export const getConfiguracao = (id) => async (dispatch) => {
  try {
    dispatch(getConfiguracaoRequest());
    const { data } = await configuracaoApi.getConfiguracao(id);
    dispatch(getConfiguracaoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getConfiguracaoError(e));
    dispatch(displayError(e));
  }
};

export const updateConfiguracao = (form) => async (dispatch) => {
  try {
    dispatch(updateConfiguracaoRequest());
    const { data } = await configuracaoApi.updateConfiguracao(form);
    dispatch(updateConfiguracaoSuccess(data));
    dispatch(displaySuccess('Configuração atualizada com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(updateConfiguracaoError(e));
    dispatch(displayError(e));
  }
};
