import { handleActions } from 'redux-actions';
import {
  getConfiguracaoRequest,
  getConfiguracaoSuccess,
  getConfiguracaoError,
  updateConfiguracaoRequest,
  updateConfiguracaoSuccess,
  updateConfiguracaoError,
} from '../actions/configuracaoActions';

const defaultState = {
  configuracao: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getConfiguracaoRequest](state) {
      return {
        ...state,
        configuracao: {},
        isFetching: true,
        error: null,
      };
    },
    [getConfiguracaoSuccess](state, { payload }) {
      return {
        ...state,
        configuracao: payload,
        isFetching: false,
        error: null,
      };
    },
    [getConfiguracaoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateConfiguracaoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateConfiguracaoSuccess](state, { payload }) {
      return {
        ...state,
        configuracao: payload,
        isFetching: false,
        error: null,
      };
    },
    [updateConfiguracaoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
