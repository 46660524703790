import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ConfiguracaoIngressoForm from './components/ConfiguracaoIngressoForm';

const ConfiguracaoIngresso = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configurações - Ingresso</h3>
          <h3 className="page-subhead subhead">
            Atenção ao alterar as configurações do site!{' '}
            <strong>As alterações passam a valer imediatamente!!</strong>
          </h3>
        </Col>
      </Row>
      <Row>
        <ConfiguracaoIngressoForm
          isHorizontal
          isAboveError={false}
          props={props}
        />
      </Row>
    </Container>
  );
};

export default ConfiguracaoIngresso;
