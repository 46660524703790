import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import StarIcon from 'mdi-react/StarIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import CartOutlineIcon from 'mdi-react/CartOutlineIcon';
import WidgetQuantidade from './components/WidgetQuantidade';
import IngressosPorEvento from './components/IngressosPorEvento';
import { getStatsEventos } from '../../redux/actions/eventoActions';
import { getStatsUsuarios } from '../../redux/actions/usuarioActions';
import {
  fetchSearchPedidos,
  getStatsPedidos,
} from '../../redux/actions/pedidoActions';
import UltimosPedidos from './components/UltimosPedidos';

const Dashboard = ({ rtl }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user?.usuario);
  const statsEventos = useSelector((state) => state.eventos.stats);
  const statsUsuarios = useSelector((state) => state.usuarios.stats);
  const statsPedidos = useSelector((state) => state.pedidos.stats);
  const pedidos = useSelector((state) => state.pedidos.pedidos);

  useEffect(() => {
    dispatch(getStatsEventos());
    dispatch(getStatsUsuarios());
    dispatch(getStatsPedidos());
    dispatch(fetchSearchPedidos(1, 7, { sortBy: { createdAt: -1 } }));
  }, [dispatch]);

  if (user && user.funcao === 3) {
    return <Redirect to="/checkin" />;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Painel</h3>
        </Col>
      </Row>
      <Row>
        <WidgetQuantidade
          quantidade={statsEventos.total}
          titulo="Eventos cadastrados"
          icone={StarOutlineIcon}
        />
        <WidgetQuantidade
          quantidade={statsEventos.totalAtivos}
          titulo="Eventos ativos"
          icone={StarIcon}
        />
        <WidgetQuantidade
          quantidade={statsUsuarios.total}
          titulo="Clientes cadastrados"
          icone={AccountGroupIcon}
        />
        <WidgetQuantidade
          quantidade={statsPedidos.total}
          titulo="Pedidos no mês"
          icone={CartOutlineIcon}
        />
      </Row>
      <Row>
        <IngressosPorEvento eventos={statsEventos.ingressosPorEvento} />
        <UltimosPedidos pedidos={pedidos} />
      </Row>
    </Container>
  );
};

export default Dashboard;
