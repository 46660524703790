import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from 'reactstrap';
import { BadgeEvento } from '@/shared/components/Badges';
import LinkIcon from 'mdi-react/LinkIcon';
import {
  cancelarEvento,
  encerrarEvento,
  publicarEvento,
} from '../../../redux/actions/eventoActions';

const EventoActionBar = ({ props }) => {
  const dispatch = useDispatch();
  const evento = useSelector((state) => state.eventos.evento);
  const { id } = props.match.params;

  const handlePublicarEvento = () => {
    dispatch(publicarEvento(id));
  };

  const handleCancelarEvento = () => {
    dispatch(cancelarEvento(id));
  };

  const handleEncerrarEvento = () => {
    dispatch(encerrarEvento(id));
  };

  return !id ? (
    ''
  ) : (
    <Row>
      <Col md={7}>
        <Card>
          <CardBody>
            <h5 className="page-title mb-2">Informações</h5>
            <div>
              Este evento está: <BadgeEvento status={evento.status} />
            </div>
            <LinkIcon />{' '}
            <a
              href={`https://www.toinhabrasilshow.com/evento/${evento.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {`https://www.toinhabrasilshow.com/evento/${evento.url}`}
            </a>
          </CardBody>
        </Card>
      </Col>
      <Col md={5}>
        <Card>
          <CardBody className="text-center">
            <h5 className="page-title mb-2">Ações</h5>
            <ButtonToolbar className="justify-content-center">
              {['rascunho', 'inativo'].includes(evento.status) && (
                <Button
                  color="success"
                  className="btn-sm"
                  onClick={() => handlePublicarEvento()}
                >
                  Publicar Evento
                </Button>
              )}
              {['ativo'].includes(evento.status) && (
                <Button
                  color="secondary"
                  className="btn-sm"
                  onClick={() => handleEncerrarEvento()}
                >
                  Encerrar Venda
                </Button>
              )}
              {['ativo', 'venda encerrada'].includes(evento.status) && (
                <Button
                  color="danger"
                  className="btn-sm"
                  onClick={() => handleCancelarEvento()}
                >
                  Cancelar Evento
                </Button>
              )}
            </ButtonToolbar>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default EventoActionBar;
