import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getConfiguracao,
  updateConfiguracao,
} from '@/redux/actions/configuracaoActions';

const ConfiguracaoIntegracoesForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const configuracao = useSelector((state) => state.configuracao.configuracao);
  const isFetching = useSelector((state) => state.configuracao.isFetching);
  const error = useSelector((state) => state.configuracao.error);

  useEffect(() => {
    dispatch(getConfiguracao());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(updateConfiguracao(data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Col md={6} lg={9} xl={9}>
      <Card>
        <CardBody>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {configuracao && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={configuracao}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="card__title">
                    <h5 className="bold-text">Integração com a CIELO</h5>
                    <h5 className="subhead">
                      Chave de identificação para integração com a Cielo. É
                      possível encontrar estes dados em{' '}
                      <a
                        href="https://cieloecommerce.cielo.com.br/Backoffice/Merchant/Account/Details"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cielo
                      </a>
                      .
                    </h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">MerchantId</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cielo.merchantId"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">MerchantKey</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cielo.merchantKey"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="card__title">
                    <h5 className="bold-text">Integração com a Sendgrid</h5>
                    <h5 className="subhead">
                      Código de API para o envio de emails. Para obter a chave
                      de integração, acesse o site da{' '}
                      <a
                        href="https://app.sendgrid.com/settings/api_keys"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sendgrid
                      </a>
                      .
                    </h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">ApiKey</span>
                    <div className="form__form-group-field">
                      <Field
                        name="sendgrid.apiKey"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ConfiguracaoIntegracoesForm;
