import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Account/LogIn/index';
import ChangePassword from '../Account/ChangePassword/index';
import Dashboard from '../Dashboard/index';
import ResetPassword from '../Account/ResetPassword/index';
import Eventos from '../Eventos';
import Evento from '../Evento';
import Cupons from '../Cupons';
import Cupom from '../Cupom';
import Pedidos from '../Pedidos';
import Pedido from '../Pedido';
import Usuarios from '../Usuarios';
import Usuario from '../Usuario';
import Ingressos from '../Ingressos';
import Ingresso from '../Ingresso';
import Checkins from '../Checkins';
import CheckinsQrCode from '../CheckinsQrCode';
import ConfiguracaoLoja from '../Configuracao/Loja/ConfiguracaoLoja';
import ConfiguracaoIntegracoes from '../Configuracao/Integracoes/ConfiguracaoIntegracoes';
import ConfiguracaoIngresso from '../Configuracao/Ingresso/ConfiguracaoIngresso';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/eventos" component={Eventos} />
      <PrivateRoute path="/evento/novo" component={Evento} />
      <PrivateRoute path="/evento/editar/:id" component={Evento} />
      <PrivateRoute path="/cupons" component={Cupons} />
      <PrivateRoute path="/cupom/novo" component={Cupom} />
      <PrivateRoute path="/cupom/editar/:id" component={Cupom} />
      <PrivateRoute path="/pedidos" component={Pedidos} />
      <PrivateRoute path="/pedido/:id" component={Pedido} />
      <PrivateRoute path="/usuarios" component={Usuarios} />
      <PrivateRoute path="/usuario/novo" component={Usuario} />
      <PrivateRoute path="/usuario/editar/:id" component={Usuario} />
      <PrivateRoute path="/ingressos" component={Ingressos} />
      <PrivateRoute path="/ingresso/:id" component={Ingresso} />
      <PrivateRoute path="/checkin" component={Checkins} />
      <PrivateRoute path="/checkinsqrcode" component={CheckinsQrCode} />
      <PrivateRoute path="/configuracao/loja" component={ConfiguracaoLoja} />
      <PrivateRoute
        path="/configuracao/integracoes"
        component={ConfiguracaoIntegracoes}
      />
      <PrivateRoute
        path="/configuracao/ingressos"
        component={ConfiguracaoIngresso}
      />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/recuperar-senha" component={ResetPassword} />
        <Route
          exact
          path="/trocar-senha/:reset_password_link"
          component={ChangePassword}
        />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
