import { JWT_KEY } from './constants';
import { regexFiltrarNaN, regexMaskTelefone } from './constants';

export * from './constants';

export const API = process.env.REACT_APP_API_URL;

export const getToken = () => {
  const usuario = JSON.parse(localStorage.getItem(JWT_KEY));

  if (usuario) {
    return usuario.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const { usuario } = JSON.parse(localStorage.getItem(JWT_KEY));

  if (usuario) {
    return usuario._id;
  } else {
    return '';
  }
};

/**
 *
 * @param {[{name: String, mask: Function}]} fields fields to format
 * @param {*} values form
 * @param {*} formRef
 */
export const formatFields = (fields, formRef) => {
  const { values } = formRef.current.getState();
  for (let field of fields) {
    const { name, mask } = field;
    values[name] && formRef.current.change(name, mask(values[name]));
  }
};

// Máscara da Identidade
export const cpfMask = (value) => {
  return value
    .replace(regexFiltrarNaN, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2') // captura 3 grupos de número com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const telefoneMask = (value) => {
  const cleaned = value.replace(regexFiltrarNaN, '');
  const match = cleaned.match(regexMaskTelefone)?.groups;

  if (!match) return cleaned;

  for (let i in match) {
    if (!match[i]) {
      match[i] = '';
    }
  }
  if (match.long) return match.long.substr(0, 11);

  if (match.ddd && !match.first) return match.ddd;

  if (match.last) return `(${match.ddd}) ${match.first}-${match.last}`;

  if (match.first) return `(${match.ddd}) ${match.first}`;

  return cleaned;
};

export const listaSituacaoEvento = [
  { value: 'ativo', label: 'ativo' },
  { value: 'inativo', label: 'inativo' },
  { value: 'rascunho', label: 'rascunho' },
  { value: 'venda encerrada', label: 'venda encerrada' },
  { value: 'encerrado', label: 'encerrado' },
];

export const listaTipoEvento = [
  { value: 'Venda Direta', label: 'Venda Direta' },
  { value: 'Venda Externa', label: 'Venda Externa' },
];

export const listaTipoDesconto = [
  { value: 'percentual', label: 'Percentual' },
  { value: 'valor', label: 'Valor' },
];

export const listaSituacaoIngresso = [
  { value: 'aguardando check-in', label: 'aguardando check-in' },
  { value: 'check-in realizado', label: 'check-in realizado' },
  { value: 'cancelado', label: 'cancelado' },
];

export const listaStatusPedido = [
  { value: 'Aguardando', label: 'Aguardando' },
  { value: 'Devolvido', label: 'Devolvido' },
  { value: 'Falhou', label: 'Falhou' },
  { value: 'Pago', label: 'Pago' },
  { value: 'Pendente', label: 'Pendente' },
];

export const priceFormatter = (price, display = true) => {
  if (typeof price === 'string') {
    price = parseFloat(
      price.replace(/[^\d,.]+/, '').replace(/(,)(?!.*,)/, '.')
    );
  }
  if (price) {
    price = price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    if (!display) {
      price = price.replace(/([R$ ])+/, '');
    }
    return price;
  } else return '-';
};

export const valorEventoFormatter = (evento) => {
  if (!evento || evento.tipo === 'Venda Externa') {
    return priceFormatter(0, true);
  }
  const listaDeValores = evento.categoriaIngresso.map(
    (categoria) => categoria.valor
  );
  const maxValue = Math.max(...listaDeValores);
  const minValue = Math.min(...listaDeValores);
  return minValue === maxValue
    ? priceFormatter(minValue)
    : `${priceFormatter(minValue)} - ${priceFormatter(maxValue)}`;
};

export const translatePaymentType = (pagamento) => {
  const tipo = pagamento?.log?.payment?.type;
  switch (tipo) {
    case 'CreditCard':
      return 'Cartão de Crédito';
    case 'DebitCard':
      return 'Cartão de Débito';
    case 'qrcode':
      return 'QR-Code';
    default:
      return tipo;
  }
};
