import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Spinner,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import renderSelectField from '@/shared/components/form/Select';
import {
  fetchSearchIngressos,
  reenviarIngresso,
  putReativarIngresso,
} from '@/redux/actions/ingressoActions';
import { getUserProfile } from '../../../redux/actions/authActions';

import validate from './validate';
import { BadgeIngresso } from '@/shared/components/Badges';

import EyeIcon from 'mdi-react/EyeIcon';
import FileRefresh from 'mdi-react/FileRefreshIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';

import { listaSituacaoIngresso, priceFormatter } from '@/utils/helpers';
import SelectEvento from '../../Comum/SelectEvento';
import DropDownUsuariosSelect from '../../Comum/DropDownUsuariosSelect';

const colunas = [
  {
    Header: '#Ingresso',
    accessor: 'ingresso',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Nome',
    accessor: 'usuario',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Evento',
    accessor: 'evento',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Valor',
    accessor: 'valorIngresso',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Situação',
    accessor: 'status',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Ação',
    accessor: 'acao',
    disableGlobalFilter: true,
    width: 80,
  },
];

const IngressosList = () => {
  const dispatch = useDispatch();
  const ingressos = useSelector((state) => state.ingressos.ingressos);
  const totalPages = useSelector((state) => state.ingressos.totalPages);
  const totalItems = useSelector((state) => state.ingressos.totalItems);
  const isFetching = useSelector((state) => state.ingressos.isFetching);
  const isUpdating = useSelector((state) => state.ingressos.isUpdating);
  const usuario = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  moment.locale('pt-br');

  const dadosTabela = (ingressos) => {
    return ingressos.map((ingresso) => ({
      ingresso: ingresso._id,
      usuario: ingresso?.titular?.nome,
      email: ingresso?.titular?.email,
      evento: ingresso.evento.nome,
      status: <BadgeIngresso status={ingresso.status} />,
      valorIngresso: priceFormatter(ingresso.valor),
      createdAt: moment(ingresso.createdAt).format('DD/MM/yyyy - HH:mm'),
      acao: (
        <div className="list__icon-btn-group">
          <Link to={`/ingresso/${ingresso._id}`}>
            <button className="icon-btn primary" id="ver" type="button">
              <EyeIcon />
            </button>
          </Link>
          {ingresso.status !== 'cancelado' && (
            <>
              <button
                className="icon-btn warning"
                id="reenviar"
                type="button"
                onClick={() => handleReenviarIngresso(ingresso._id)}
              >
                <FileRefresh />
              </button>
              <UncontrolledTooltip placement="left" target="reenviar">
                Reenviar Ingresso
              </UncontrolledTooltip>
            </>
          )}
          {ingresso.status === 'cancelado' && usuario?.funcao === (1 || 2) && (
            <>
              <button
                className="icon-btn warning"
                id="checkout"
                type="button"
                onClick={() => handleReativarIngresso(ingresso._id)}
              >
                <AutorenewIcon />
              </button>
              <UncontrolledTooltip placement="left" target="checkout">
                Reativar Ingresso
              </UncontrolledTooltip>
            </>
          )}
          <UncontrolledTooltip placement="left" target="ver">
            Detalhes do Ingresso
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSearchIngressos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      status: data.statusSelect?.value,
      evento: data.eventosSelect?.value,
      usuario: data.usuariosSelect?.value,
    };
    setSearch(searchForm);
  };

  const handleReenviarIngresso = (id) => {
    dispatch(reenviarIngresso(id));
  };

  const handleReativarIngresso = (id) => {
    dispatch(putReativarIngresso(id));
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Ingressos emitidos</h5>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          placeholder="Pesquisa..."
                          component={FormField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <DropDownUsuariosSelect />
                  </Col>
                  <Col xl={4}>
                    <SelectEvento />
                  </Col>
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          type="text"
                          placeholder="Situação"
                          options={(() =>
                            [].concat(
                              [{ value: undefined, label: 'Todos' }],
                              listaSituacaoIngresso
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {ingressos && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(ingressos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default IngressosList;
