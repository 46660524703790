import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PedidosList from './components/PedidosList';

const Pedidos = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Pedidos</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os pedidos feitos na plataforma, mostrando os mais
          recentes primeiro
        </h3>
      </Col>
    </Row>
    <Row>
      <PedidosList />
    </Row>
  </Container>
);

export default Pedidos;
