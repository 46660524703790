import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIngresso,
  putCheckinIngresso,
  putCheckoutIngresso,
  cancelarIngresso,
} from '../../../redux/actions/ingressoActions';

import { QrReader } from 'react-qr-reader';

import moment from 'moment';
import 'moment/locale/pt-br';

import { BadgeIngresso } from '@/shared/components/Badges';
import {
  Row,
  Col,
  Button,
  Spinner,
  Container,
  Card,
  CardBody,
} from 'reactstrap';

const QrCodeScan = ({ props, modeQr }) => {
  const dispatch = useDispatch();
  const ingresso = useSelector((state) => state.ingressos.ingresso);
  const isFetching = useSelector((state) => state.ingressos.isFetching);
  const isUpdating = useSelector((state) => state.ingressos.isUpdating);
  const [qrResult, setQrResult] = useState('');
  moment.locale('pt-br');

  useEffect(() => {
    if (qrResult) {
      const searchForm = {};
      searchForm.filters = {
        // evento: data.eventosSelect?.value,
        _id: qrResult,
      };
      dispatch(getIngresso(qrResult));
    }
  }, [qrResult, dispatch]);

  const handleCheckinIngresso = (id) => {
    dispatch(putCheckinIngresso(id));
  };

  const handleCheckoutIngresso = (id) => {
    dispatch(putCheckoutIngresso(id));
  };

  const handleCancelarIngresso = (id) => {
    dispatch(cancelarIngresso(id));
  };

  return (
    <>
      {!qrResult && (
        <Col mb={4} lg={8}>
          <Card>
            <CardBody>
              <Container>
                <div className="card__title">
                  <h4 className="bold-text">Apresente o Qr Code</h4>
                </div>
                <QrReader
                  className="qrscan"
                  constraints={{ facingMode: 'environment' }}
                  onResult={(result, error) => {
                    if (!!result) {
                      setQrResult(result?.text);
                    }
                  }}
                  videoStyle={{ height: '70%' }}
                />
              </Container>
            </CardBody>
          </Card>
        </Col>
      )}
      {qrResult && (
        <Col md={8} lg={8}>
          <Card>
            <CardBody>
              <Container>
                <Container className="qrcontent">
                  <div className="card__title">
                    <h4 className="bold-text">Informações do Ingresso</h4>
                  </div>
                  {isFetching || isUpdating ? (
                    <Spinner className="table-fetch-spinner" />
                  ) : (
                    <div>
                      <Row>
                        <Col md={6} lg={6} className="componentimgqr">
                          <img
                            src={ingresso?.evento?.urlImagens[0]}
                            alt="capa-evento"
                          />
                        </Col>
                        <Col md={6} lg={6}>
                          <Row>
                            <Row className="pb-2 font-weight-bold">
                              #Ingresso:
                            </Row>
                            <Row className="pb-2 pl-5">#{ingresso?._id}</Row>
                          </Row>
                          <Row>
                            <Row className="pb-2 font-weight-bold">
                              Convidado:
                            </Row>
                            <Row className="pb-2 pl-5">
                              {ingresso?.titular?.nome}
                            </Row>
                          </Row>
                          <Row>
                            <Row className="pb-2 font-weight-bold">Email:</Row>
                            <Row className="pb-2 pl-5">
                              {ingresso?.titular?.email}
                            </Row>
                          </Row>
                          <Row>
                            <Row className="pb-2 font-weight-bold">Evento:</Row>
                            <Row className="pb-2 pl-5">{`${
                              ingresso?.evento?.nome
                            } - ${moment(
                              ingresso?.evento?.dataInicioEvento
                            ).format('DD/MM/YYYY')}`}</Row>
                          </Row>
                          <Row>
                            <Row className="pb-2 font-weight-bold">
                              Situação:{' '}
                            </Row>
                            <Row className="badgestatus">
                              <BadgeIngresso status={ingresso.status} />
                            </Row>
                          </Row>
                          <br />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Container>
                {['aguardando check-in', 'check-in realizado'].includes(
                  ingresso.status
                ) && (
                  <Container className="qrbuttons">
                    <Row>
                      {['aguardando check-in'].includes(ingresso.status) && (
                        <Col md={12}>
                          <Button
                            onClick={() => handleCheckinIngresso(ingresso._id)}
                            className="d-inline-block"
                            color="success"
                          >
                            Check-in
                          </Button>
                        </Col>
                      )}
                      {['check-in realizado'].includes(ingresso.status) && (
                        <Col md={12}>
                          <Button
                            onClick={() => handleCheckoutIngresso(ingresso._id)}
                            className="d-inline-block"
                          >
                            Check-out
                          </Button>
                        </Col>
                      )}
                      <Col md={12}>
                        <Button
                          onClick={() => handleCancelarIngresso(ingresso._id)}
                          className="d-inline-block"
                          color="primary"
                        >
                          Cancelar Ingresso
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                )}
                <Container className="qrbuttons">
                  <Col md={12}>
                    <Button
                      onClick={() => setQrResult('')}
                      className="d-inline-block"
                    >
                      Reiniciar
                    </Button>
                  </Col>
                </Container>
              </Container>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default QrCodeScan;
