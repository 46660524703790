import { handleActions } from 'redux-actions';
import {
  getIngressoRequest,
  getIngressoSuccess,
  putCheckinRequest,
  putCheckinSuccess,
  putCheckoutRequest,
  putCheckoutSuccess,
  putReativarRequest,
  putReativarSuccess,
  fetchIngressosRequest,
  fetchIngressosSuccess,
  fetchSearchIngressosRequest,
  fetchSearchIngressossSuccess,
  cancelarIngressoRequest,
  cancelarIngressoSuccess,
} from '../actions/ingressoActions';

const defaultState = {
  ingressos: [],
  totalPages: 0,
  ingresso: {},
  count: 0,
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getIngressoRequest](state) {
      return {
        ...state,
        ingresso: {},
        isFetching: true,
        error: null,
      };
    },
    [getIngressoSuccess](state, { payload }) {
      return {
        ...state,
        ingresso: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchIngressosRequest](state) {
      return {
        ...state,
        ingressos: [],
        isFetching: true,
        error: null,
      };
    },
    [fetchIngressosSuccess](state, { payload }) {
      return {
        ...state,
        ingressos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchIngressosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchIngressossSuccess](state, { payload }) {
      return {
        ...state,
        ingressos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [putCheckinRequest](state) {
      return {
        ...state,
        ingresso: {},
        isUpdating: true,
        error: null,
      };
    },
    [putCheckinSuccess](state, { payload }) {
      return {
        ...state,
        ingresso: payload,
        isUpdating: false,
        error: null,
      };
    },
    [putCheckoutRequest](state) {
      return {
        ...state,
        ingresso: {},
        isUpdating: true,
        error: null,
      };
    },
    [putCheckoutSuccess](state, { payload }) {
      return {
        ...state,
        ingresso: payload,
        isUpdating: false,
        error: null,
      };
    },
    [putReativarRequest](state) {
      return {
        ...state,
        ingresso: {},
        isUpdating: true,
        error: null,
      };
    },
    [putReativarSuccess](state, { payload }) {
      return {
        ...state,
        ingresso: payload,
        isUpdating: false,
        error: null,
      };
    },
    [cancelarIngressoRequest](state) {
      return {
        ...state,
        ingresso: {},
        isUpdating: true,
        error: null,
      };
    },
    [cancelarIngressoSuccess](state, { payload }) {
      return {
        ...state,
        ingresso: payload,
        isUpdating: false,
        error: null,
      };
    },
  },
  defaultState
);
