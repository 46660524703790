import { cpf } from 'cpf-cnpj-validator';
import {
  regexFiltrarNaN,
} from '../../../utils/constants.js';

/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo não pode estar vazio';
  }
  
  const fields = [
    'nome',
    'email',
  ];

  for (let field of fields) {
    if (!values[field]) {
      errors[field] = 'O campo não pode estar vazio';
    }
  }

  if (values.cpf && !cpf.isValid(values.cpf)) {
    errors.cpf = 'CPF inválido';
  }

  if (values.telefone) {
    let cel = values.telefone.replace(regexFiltrarNaN, '');
    if (cel.length !== 11) {
      errors.telCelular = 'Número de celular inválido';
    }
  }

  return errors;
};

export default validate;
