import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Spinner,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import SelectField from '@/shared/components/form/Select';
import renderIntervalDatePickerField from '@/shared/components/form/IntervalDatePicker';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchSearchPedidos } from '../../../redux/actions/pedidoActions';
import validate from './validate';
import { listaStatusPedido } from '../../../utils/helpers';
import { BadgePedido } from '../../../shared/components/Badges';
import { priceFormatter } from '../../../utils/helpers';
import DropDownUsuariosSelect from '../../Comum/DropDownUsuariosSelect';

const colunas = [
  {
    Header: 'Nome do cliente',
    accessor: 'usuario',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Situação',
    accessor: 'status',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Data do pedido',
    accessor: 'createdAt',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Valor',
    accessor: 'totalPedido',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Ação',
    accessor: 'acao',
    disableGlobalFilter: true,
    width: 80,
  },
];

const PedidosList = () => {
  const dispatch = useDispatch();
  const pedidos = useSelector((state) => state.pedidos.pedidos);
  const totalPages = useSelector((state) => state.pedidos.totalPages);
  const totalItems = useSelector((state) => state.pedidos.totalItems);
  const isFetching = useSelector((state) => state.pedidos.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  moment.locale('pt-br');

  const dadosTabela = (pedidos) => {
    return pedidos.map((pedido) => ({
      usuario: pedido.usuario.nome,
      status: <BadgePedido status={pedido.status} />,
      totalPedido: `${priceFormatter(pedido.totalPedido)}`,
      createdAt: moment(pedido.createdAt).format('DD/MM/yyyy - HH:mm'),
      acao: (
        <>
          <Link
            color="primary"
            className="icon-btn success"
            type="button"
            to={`/pedido/${pedido._id}`}
            id="ver"
          >
            <EyeIcon />
          </Link>
          <UncontrolledTooltip placement="left" target="ver">
            Detalhes do Pedido
          </UncontrolledTooltip>
        </>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchPedidos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.filters = {
      status: data.statusSelect?.value,
      usuario: data.usuariosSelect?.value,
    };
    searchForm.dateFilters = {
      createdAt: data.createdAt === null ? data.createdAt : undefined,
    };
    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Pedidos realizados</h5>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={4} className="align-self-end text-end">
                    <DropDownUsuariosSelect />
                  </Col>
                  <Col md={2} className="align-self-end text-end">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          placeholder="Situação"
                          component={SelectField}
                          options={(() =>
                            [{ value: undefined, label: 'Todos' }].concat(
                              listaStatusPedido
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="createdAt"
                          component={renderIntervalDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={2} className="align-self-end text-end">
                    <Button color="primary" type="submit" className=" btn-sm">
                      Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {pedidos && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(pedidos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PedidosList;
