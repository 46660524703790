import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Button,
  Card,
  CardBody,
  Col,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import RocketLaunchIcon from 'mdi-react/RocketLaunchIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import LockIcon from 'mdi-react/LockIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import { Link, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import FormField from '@/shared/components/form/FormField';
import Modal from '@/shared/components/Modal';
import renderSelectField from '@/shared/components/form/Select';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import {
  fetchSearchEventos,
  deleteEvento,
  cancelarEvento,
  encerrarEvento,
  publicarEvento,
} from '@/redux/actions/eventoActions';
import { BadgeEvento } from '@/shared/components/Badges';
import {
  listaSituacaoEvento,
  valorEventoFormatter,
} from '../../../utils/helpers';
import { getRelatorioEvento } from '../../../redux/actions/eventoActions';

const EventosList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const eventos = useSelector((state) => state.eventos.eventos);
  const totalPages = useSelector((state) => state.eventos.totalPages);
  const totalItems = useSelector((state) => state.eventos.totalItems);
  const isFetching = useSelector((state) => state.eventos.isFetching);
  const isUpdating = useSelector((state) => state.eventos.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});

  const colunas = useMemo(
    () => [
      {
        Header: 'Nome do evento',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'Data/hora do evento',
        accessor: 'dataInicioEvento',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Preço',
        accessor: 'preco',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Situação',
        accessor: 'status',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    []
  );

  const dadosTabela = (eventos) => {
    return eventos.map((evento) => ({
      nome: evento.nome,
      dataInicioEvento: moment(evento.dataInicioEvento).format(
        'DD/MM/yyyy - HH:mm'
      ),
      preco: valorEventoFormatter(evento),
      status: <BadgeEvento status={evento.status} />,
      acao: (
        <div className="list__icon-btn-group">
          {['rascunho', 'inativo', 'encerrado'].includes(evento.status) && (
            <>
              <button
                className="icon-btn success"
                id="publicar"
                type="button"
                onClick={() => handlePublicarEvento(evento._id)}
              >
                <RocketLaunchIcon />
              </button>
              <UncontrolledTooltip placement="left" target="publicar">
                Publicar Evento
              </UncontrolledTooltip>
            </>
          )}
          {['ativo'].includes(evento.status) && (
            <>
              <button
                className="icon-btn warning"
                id="encerrar-venda"
                type="button"
                onClick={() => handleEncerrarEvento(evento._id)}
              >
                <LockIcon />
              </button>
              <UncontrolledTooltip placement="left" target="encerrar-venda">
                Encerrar Venda de Ingressos
              </UncontrolledTooltip>
            </>
          )}
          {['ativo', 'venda encerrada'].includes(evento.status) && (
            <>
              <button
                className="icon-btn danger"
                id="cancelar"
                type="button"
                onClick={() => handleCancelarEvento(evento._id)}
              >
                <CancelIcon />
              </button>
              <UncontrolledTooltip placement="left" target="cancelar">
                Cancelar Evento
              </UncontrolledTooltip>
            </>
          )}
          {evento.tipo === 'Venda Direta' && (
            <>
              <button
                className="icon-btn warning"
                id="relatorio"
                type="button"
                onClick={() => handleRelatorioVendas(evento._id)}
              >
                <FileDownloadIcon />
              </button>
              <UncontrolledTooltip placement="left" target="relatorio">
                Relatório de Vendas
              </UncontrolledTooltip>
            </>
          )}
          <button
            className="icon-btn success"
            id="editar"
            type="button"
            onClick={() => history.push(`/evento/editar/${evento._id}`)}
          >
            <EditIcon />
          </button>
          <UncontrolledTooltip placement="left" target="editar">
            Editar Evento
          </UncontrolledTooltip>
          <Modal
            color="danger"
            title="Excluir Evento"
            accept="Excluir"
            dismiss="Cancelar"
            btn={
              <button className="icon-btn danger" type="button" id="excluir">
                <TrashIcon />
              </button>
            }
            onAccept={async () => {
              await dispatch(deleteEvento(evento._id)).then(setPage(0));
            }}
          >
            <span>
              Você realmente deseja excluir <br /> o evento&nbsp;
              <b>{evento.nome}</b> permanentemente?
            </span>
          </Modal>
          <UncontrolledTooltip placement="left" target="excluir">
            Excluir Evento
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  // fetch eventos when page load
  useEffect(() => {
    dispatch(fetchSearchEventos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      status: data.statusSelect?.value,
    };
    setSearch(searchForm);
  };

  const handlePublicarEvento = (id) => {
    dispatch(publicarEvento(id));
  };

  const handleCancelarEvento = (id) => {
    dispatch(cancelarEvento(id));
  };

  const handleEncerrarEvento = (id) => {
    dispatch(encerrarEvento(id));
  };

  const handleRelatorioVendas = (id) => {
    dispatch(getRelatorioEvento(id));
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Eventos cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link className="btn btn-primary list__btn-add" to="/evento/novo">
                <PlusIcon /> Novo evento
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          type="text"
                          placeholder="Situação"
                          options={(() =>
                            [].concat(
                              [{ value: undefined, label: 'Todos' }],
                              listaSituacaoEvento
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {(isFetching || isUpdating) && (
            <Spinner className="table-fetch-spinner" />
          )}
          {eventos && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(eventos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default EventosList;
