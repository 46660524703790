import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Modal,
  Spinner,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import renderSelectField from '@/shared/components/form/Select';
import {
  fetchSearchIngressos,
  putCheckinIngresso,
  putCheckoutIngresso,
  cancelarIngresso,
} from '@/redux/actions/ingressoActions';
import validate from './validate';
import { BadgeIngresso } from '@/shared/components/Badges';
import { fetchUsuarios } from '../../../redux/actions/usuarioActions';
import { fetchSearchEventos } from '../../../redux/actions/eventoActions';
import QrCodeScan from './QrCodeScan';

import QrCodeScanIcon from 'mdi-react/QrcodeScanIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import CancelIcon from 'mdi-react/CancelIcon';

const colunas = [
  {
    Header: '#Ingresso',
    accessor: 'ingresso',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Nome',
    accessor: 'usuario',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Evento',
    accessor: 'evento',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Situação',
    accessor: 'status',
    disableGlobalFilter: true,
    width: 80,
  },
  {
    Header: 'Ação',
    accessor: 'acao',
    disableGlobalFilter: true,
    width: 80,
  },
];

const filtro = { filters: { status: { $in: ['ativo', 'venda encerrada'] } } };

const CheckinsList = ({ isAboveError, props }) => {
  const dispatch = useDispatch();
  const ingressos = useSelector((state) => state.ingressos.ingressos);
  const eventos = useSelector((state) => state.eventos.eventos);
  const totalPages = useSelector((state) => state.ingressos.totalPages);
  const totalItems = useSelector((state) => state.ingressos.totalItems);
  const isFetching = useSelector((state) => state.ingressos.isFetching);
  const isUpdating = useSelector((state) => state.ingressos.isUpdating);
  const [modeQrCode, setModeQrCode] = useState('Scan');
  const [showModalQrCode, setShowModalQrCode] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState();
  moment.locale('pt-br');
  const required = (value) => (value ? undefined : 'Campo Obrigatório');

  const dadosTabela = (ingressos) => {
    return ingressos.map((ingresso) => ({
      ingresso: ingresso._id,
      usuario: ingresso?.titular?.nome,
      email: ingresso?.titular?.email,
      evento: ingresso.evento.nome,
      status: <BadgeIngresso status={ingresso.status} />,
      createdAt: moment(ingresso.createdAt).format('DD/MM/yyyy - HH:mm'),
      acao: (
        <div className="list__icon-btn-group">
          {['aguardando check-in'].includes(ingresso.status) && (
            <>
              <button
                className="icon-btn success"
                onClick={() => handleCheckinIngresso(ingresso._id)}
                type="button"
                id="checkin"
              >
                <CheckIcon />
                <UncontrolledTooltip placement="left" target="checkin">
                  Fazer Checkin
                </UncontrolledTooltip>
              </button>
            </>
          )}
          {['aguardando check-in', 'check-in realizado'].includes(
            ingresso.status
          ) && (
            <>
              <button
                className="icon-btn danger"
                onClick={() => handleCancelarIngresso(ingresso._id)}
                to={`#`}
                type="button"
                id="cancelar"
              >
                <CancelIcon />
                <UncontrolledTooltip placement="left" target="cancelar">
                  Cancelar Evento
                </UncontrolledTooltip>
              </button>
            </>
          )}
          {['check-in realizado'].includes(ingresso.status) && (
            <>
              <button
                className="icon-btn success"
                onClick={() => handleCheckoutIngresso(ingresso._id)}
                type="button"
                id="checkout"
              >
                <AutorenewIcon />
                <UncontrolledTooltip placement="left" target="checkout">
                  Fazer Checkout
                </UncontrolledTooltip>
              </button>
            </>
          )}
        </div>
      ),
    }));
  };

  const toggle = () => {
    setShowModalQrCode((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchSearchEventos(1, 999, filtro));
  }, [dispatch, isUpdating]);

  useEffect(() => {
    dispatch(fetchUsuarios(1, 999999));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSearchIngressos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  const handleCancelarIngresso = (id) => {
    dispatch(cancelarIngresso(id));
  };

  const handleCheckoutIngresso = (id) => {
    dispatch(putCheckoutIngresso(id));
  };

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      evento: data.eventosSelect?.value,
    };
    setSearch(searchForm);
  };

  const handleCheckinIngresso = (id) => {
    dispatch(putCheckinIngresso(id));
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Ingressos emitidos</h5>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          validate={required}
                          name="eventosSelect"
                          component={renderSelectField}
                          type="text"
                          placeholder="Evento"
                          isAboveError={isAboveError}
                          options={(() =>
                            [].concat(
                              eventos.map((evento) => ({
                                value: evento._id,
                                label: `${evento.nome} - ${moment(
                                  evento.dataInicioEvento
                                ).format('DD/MM/YYYY')}`,
                              }))
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          placeholder="Nome do convidado..."
                          component={FormField}
                        />
                        <button
                          className="form__form-group-button"
                          onClick={() => {
                            setShowModalQrCode(true);
                          }}
                          type="button"
                        >
                          <QrCodeScanIcon size={12} />
                        </button>
                        <Modal
                          isOpen={showModalQrCode}
                          toggle={toggle}
                          className="qrcontent"
                          onClosed={() => setModeQrCode('Scan')}
                        >
                          <QrCodeScan props={props} modeQr={setModeQrCode} />
                          {modeQrCode === 'Scan' && (
                            <Button onClick={() => setShowModalQrCode(false)}>
                              Cancelar
                            </Button>
                          )}
                        </Modal>
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} className="align-self-start">
                    <Button color="primary" type="submit" className="btn-sm">
                      Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {(isFetching || isUpdating) && (
            <Spinner className="table-fetch-spinner" />
          )}
          {ingressos && search && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(ingressos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CheckinsList;
