import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import PedidoDetalhe from './components/PedidoDetalhe';

const Pedido = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Detalhes do Pedido</h3>
        <h3 className="page-subhead subhead">
          Verifique os detalhes do pedido, cliente, eventos, pagamento e
          ingressos relacionados
        </h3>
      </Col>
    </Row>
    <Row>
      <PedidoDetalhe props={props} />
    </Row>
  </Container>
);

export default Pedido;
