import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import cuponsApi from '../../utils/api/cuponsApi';

export const createCupomRequest = createAction('CREATE_CUPOM_REQUEST');
export const createCupomSuccess = createAction('CREATE_CUPOM_SUCCESS');

export const getCupomRequest = createAction('GET_CUPOM_REQUEST');
export const getCupomSuccess = createAction('GET_CUPOM_SUCCESS');

export const updateCupomRequest = createAction('UPDATE_CUPOM_REQUEST');
export const updateCupomSuccess = createAction('UPDATE_CUPOM_SUCCESS');

export const deleteCupomRequest = createAction('DELETE_CUPOM_REQUEST');
export const deleteCupomSuccess = createAction('DELETE_CUPOM_SUCCESS');

export const fetchCuponsRequest = createAction('FETCH_CUPONS_REQUEST');
export const fetchCuponsSuccess = createAction('FETCH_CUPONS_SUCCESS');

export const fetchSearchCuponsRequest = createAction(
  'FETCH_SEARCH_CUPONS_REQUEST'
);
export const fetchSearchCuponsSuccess = createAction(
  'FETCH_SEARCH_CUPONS_SUCCESS'
);

export const newCupomRequest = createAction('NEW_CUPOM_REQUEST');

export const fetchCupons = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchCuponsRequest());
    const { data } = await cuponsApi.listCupons(page, pageSize);
    dispatch(fetchCuponsSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const getCupom = (id) => async (dispatch) => {
  try {
    dispatch(getCupomRequest());
    const { data } = await cuponsApi.readCupom(id);
    dispatch(getCupomSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const createCupom = (form) => async (dispatch) => {
  try {
    dispatch(createCupomRequest());
    const { data } = await cuponsApi.createCupom(form);
    dispatch(displaySuccess('Cupom incluído com sucesso!'));
    dispatch(createCupomSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const updateCupom = (id, form) => async (dispatch) => {
  try {
    dispatch(updateCupomRequest());
    const { data } = await cuponsApi.updateCupom(id, form);
    dispatch(displaySuccess('Cupom atualizado com sucesso!'));
    dispatch(updateCupomSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const deleteCupom = (id) => async (dispatch) => {
  try {
    dispatch(deleteCupomRequest());
    const { data } = await cuponsApi.deleteCupom(id);
    dispatch(deleteCupomSuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const newCupom = () => async (dispatch) => {
  dispatch(newCupomRequest());
};

export const fetchSearchCupons =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchCuponsRequest());
      const { data } = await cuponsApi.searchCupons(page, pageSize, search);
      dispatch(fetchSearchCuponsSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };
