import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import QrCodeScan from './components/QrCodeScan';

const CheckinsQrCode = (props) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Check-in Qr Code</h3>
        <h3 className="page-subhead subhead">
          Exibindo o Check-in através do Qr Code emitidos pela plataforma.
        </h3>
      </Col>
    </Row>
    <Row>
      <QrCodeScan isAboveError props={props} />
    </Row>
  </Container>
);

export default CheckinsQrCode;
