import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { renderComponentField } from '@/shared/components/form/FormField';

const DatepickerField = ({ name, showName, onChange, ...props }) => {
  const [startDate] = useState(props.value || new Date());
  const [selectedDate, setSelectedDate] = useState(startDate);

  const onDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(date);
  };

  return (
    <div className="datepicker-wrapper">
      {showName && name && <p>{name}</p>}
      <DatePicker
        name={name}
        className="date-picker"
        startDate={startDate}
        selected={selectedDate}
        onChange={onDateChange}
        onSelect={onDateChange}
        onBlur={(e) => onDateChange(e.target.value)}
        {...props}
      />
    </div>
  );
};

DatepickerField.propTypes = {
  name: PropTypes.string,
  hideName: PropTypes.bool,
  onChange: PropTypes.func,
};

DatepickerField.defaultProps = {
  name: null,
  showName: false,
};

export default renderComponentField(DatepickerField);
