import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Spinner, Card, CardBody } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getUsuario,
  createUsuario,
  updateUsuario,
  newUsuario,
} from '@/redux/actions/usuarioActions';
import { cpfMask, telefoneMask, formatFields } from '../../../utils/helpers';

const UsuarioForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const usuario = useSelector((state) => state.usuarios.usuario);
  const isFetching = useSelector((state) => state.usuarios.isFetching);
  const isUpdating = useSelector((state) => state.usuarios.isUpdating);
  const error = useSelector((state) => state.usuarios.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  const formRef = useRef({});

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getUsuario(id));
    } else {
      dispatch(newUsuario());
    }
  }, [dispatch, id, isAddMode]);

  const onSubmit = (data) => {
    const formData = {};
    const fields = Object.keys(data).map((key, i) => key);
    fields.map((item) => (formData[item] = data[item]));
    isAddMode
      ? dispatch(createUsuario(formData))
      : dispatch(updateUsuario(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/usuarios" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{isAddMode ? 'Novo' : 'Editar'} Cliente</h5>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(usuario || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : usuario}
          >
            {({
              handleSubmit,
              form,
              form: { reset, pristine, submitting },
            }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={(props) => {
                      const fieldsToFormat = [
                        { name: 'cpf', mask: cpfMask },
                        { name: 'telefone', mask: telefoneMask },
                      ];
                      formatFields(fieldsToFormat, formRef);
                    }}
                  />
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nome</span>
                    <div className="form__form-group-field">
                      <Field
                        name="nome"
                        type="string"
                        component={FormField}
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="Digite o Nome"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">CPF</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cpf"
                        component={FormField}
                        type="string"
                        rules={{ required: true }}
                        isAboveError={isAboveError}
                        placeholder="Digite o CPF"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Senha</span>
                    <div className="form__form-group-field">
                      <Field
                        name="password"
                        component={PasswordField}
                        placeholder="Senha"
                        className="input-without-border-radius"
                        keyIcon
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Telefone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="telefone"
                        component={FormField}
                        type="string"
                        isAboveError={isAboveError}
                        placeholder="Digite o Telefone (DDD) *****-****"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <Field
                        name="email"
                        type="email"
                        component={FormField}
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="Digite o Email"
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

UsuarioForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

UsuarioForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default UsuarioForm;
