/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.codigo) {
    errors.codigo = 'O campo Código não pode estar vazio';
  }
  if (!values.valorDesconto) {
    errors.valorDesconto = 'O campo Valor não pode estar vazio';
  } else if (values.valorDesconto <= 0) {
    errors.valorDesconto =
      'O valor do desconto não pode ser menor ou igual a zero';
  }
  if (!values.tipoDescontoSelect) {
    errors.tipoDescontoSelect = 'Selecione uma opção, por favor';
  } else if (values.tipoDescontoSelect.value == 'percentual') {
    if (values?.valorDesconto > 100) {
      errors.valorDesconto = 'O valor não pode exceder 100%';
    }
  }

  return errors;
};

export default validate;
