import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import EventoActionBar from './components/EventoActionBar';
import EventoForm from './components/EventoForm';

const Evento = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Evento</h3>
          <h3 className="page-subhead subhead">
            Adicione um novo evento ou altere suas informações
          </h3>
        </Col>
      </Row>
      <EventoActionBar props={props} />
      <Row>
        <Col md={12}>
          <EventoForm isHorizontal={false} isAboveError props={props} />
        </Col>
      </Row>
    </Container>
  );
};

export default Evento;
