import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import EventosList from './components/EventosList';

const Eventos = ({ rtl }) => {
  return (
    <Container className="eventos">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Eventos</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize eventos que serão disponibilizados na bilheteria
            virtual.
          </h3>
        </Col>
      </Row>
      <Row>
        <EventosList />
      </Row>
    </Container>
  );
};

export default Eventos;
