export const JWT_KEY = 'BilheteMasterAdmin';

export const regexFiltrarNaN = /\D+/g;

// Máscara do Número de Telefone
export const regexMaskTelefone =
    /^(?<ddd>\d{2})(?<first>\d{1,5})?(?<last>\d{4})?$|(?<long>\d{12,})/;

// Máscara da Identidade
export const regexMaskRG =
    /^(\d{2})(\d{1,3})?(\d{1,3})?(\d{1})?$|(?<long>\d{10,})/;
