/* eslint-disable import/no-anonymous-default-export */
import { get, post, put, deleteRequest } from './base/index';
import { getUserId } from '../helpers';

export default {
  createEvento: (form) => post(`/admin/evento/create/${getUserId()}`, form),
  readEvento: (id) => get(`/admin/evento/${id}/${getUserId()}`),
  updateEvento: (id, form) => put(`/admin/evento/${id}/${getUserId()}`, form),
  deleteEvento: (id) => deleteRequest(`/admin/evento/${id}/${getUserId()}`),
  listEventos: (page, pageSize) =>
    get(`/admin/eventos/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  searchEventos: (page, pageSize, searchForm) =>
    post(
      `/admin/eventos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
  publicarEvento: (id) => put(`/admin/evento/publicar/${id}/${getUserId()}`),
  cancelarEvento: (id) => put(`/admin/evento/cancelar/${id}/${getUserId()}`),
  encerrarEvento: (id) => put(`/admin/evento/encerrar/${id}/${getUserId()}`),
  getRelatorioEvento: (id) =>
    get(`/admin/relatorio/evento/${id}/${getUserId()}`, {
      'Content-Type': 'blob',
      responseType: 'arraybuffer',
    }),
  getStatsEventos: () => get(`/admin/eventos/stats/${getUserId()}`),
};
