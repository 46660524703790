import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import moment from 'moment';
import renderSelectField from '@/shared/components/form/Select';
import { fetchSearchEventos } from '../../redux/actions/eventoActions';

const SelectEvento = ({ filtro }) => {
  const dispatch = useDispatch();
  const eventos = useSelector((state) => state.eventos.eventos);
  const filtroMemo = useMemo(()=>filtro || {}, [filtro]);

  useEffect(() => {
    dispatch(fetchSearchEventos(1, 999, filtroMemo));
  }, [dispatch, filtroMemo]);

  return (
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="eventosSelect"
          component={renderSelectField}
          type="text"
          placeholder="Evento"
          rules={{ required: 'Campo Obrigatório' }}
          options={(() =>
            [].concat(
              [{ value: undefined, label: 'Todos' }],
              eventos.map((evento) => ({
                value: evento._id,
                label: `${evento.nome} - ${moment(
                  evento.dataInicioEvento
                ).format('DD/MM/YYYY')}`,
              }))
            ))()}
        />
      </div>
    </div>
  );
};

export default SelectEvento;
