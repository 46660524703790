/* eslint-disable import/no-anonymous-default-export */
import { get, post, put, deleteRequest } from './base/index';
import { getUserId } from '../helpers';

export default {
  createCupom: (form) => post(`/admin/cupom/create/${getUserId()}`, form),
  readCupom: (id) => get(`/admin/cupom/${id}/${getUserId()}`),
  updateCupom: (id, form) => put(`/admin/cupom/${id}/${getUserId()}`, form),
  deleteCupom: (id) => deleteRequest(`/admin/cupom/${id}/${getUserId()}`),
  listCupons: (page, pageSize) =>
    get(`/admin/cupons/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  searchCupons: (page, pageSize, searchForm) =>
    post(
      `/admin/cupons/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
};
