/* eslint-disable import/no-anonymous-default-export */
import { get, post } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  fetchPedidos: (page, pageSize) =>
    get(
      `${API}/admin/pedidos/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchPedidos: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/pedidos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  getPedido: (id) => get(`${API}/admin/pedido/${id}/${getUserId()}`),
  getStatsPedidos: () => get(`/admin/pedidos/stats/${getUserId()}`),
};
