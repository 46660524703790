import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import IngressosList from './components/IngressosList';

const Ingressos = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Ingressos</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os ingressos emitidos pela plataforma, mostrando os
          mais recentes primeiro.
        </h3>
      </Col>
    </Row>
    <Row>
      <IngressosList />
    </Row>
  </Container>
);

export default Ingressos;
