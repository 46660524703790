import React from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

export const AsyncSelectField = React.forwardRef(
  ({ onChange, value, name, placeholder, defaultOptions, loadOptions }, ref) => {
    const handleChange = (selectedOption) => {
      onChange(selectedOption);
    };

    return (
      <AsyncSelect
        name={name}
        value={value}
        onChange={handleChange}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        ref={ref}
      />
    );
  }
);

AsyncSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ), PropTypes.bool]),
  loadOptions: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).isRequired,
};

AsyncSelectField.defaultProps = {
  placeholder: '',
  defaultOptions: true
};

export default renderComponentField(AsyncSelect);
