/* eslint-disable */
const validate = (values) => {
  const errors = { cielo: {}, sendgrid: {} };
  if (values.cielo?.merchantId || values.cielo?.merchantKey) {
    if (!values.cielo?.merchantId) {
      errors.cielo.merchantId = 'O campo merchantId não pode ser vazio';
    }
    if (!values.cielo?.merchantKey) {
      errors.cielo.merchantKey = 'O campo merchantKey não pode ser vazio';
    }
  }

  return errors;
};

export default validate;
