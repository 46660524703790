import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ConfiguracaoIntegracoesForm from './components/ConfiguracaoIntegracoesForm';

const ConfiguracaoIntegracoes = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configurações - Integrações</h3>
          <h3 className="page-subhead subhead">
            Atenção ao alterar as configurações do site!{' '}
            <strong>As alterações passam a valer imediatamente!!</strong>
          </h3>
        </Col>
      </Row>
      <Row>
        <ConfiguracaoIntegracoesForm
          isHorizontal
          isAboveError={false}
          props={props}
        />
      </Row>
    </Container>
  );
};

export default ConfiguracaoIntegracoes;
