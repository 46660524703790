import { createAction } from 'redux-actions';
import { displayError } from './notificacoesActions';
import pagamentosApi from '../../utils/api/pagamentosApi';

export const fetchPagamentosRequest = createAction('FETCH_PAGAMENTOS_REQUEST');
export const fetchPagamentosSuccess = createAction('FETCH_PAGAMENTOS_SUCCESS');

export const fetchPagamentos = (page, pageSize, search) => async (dispatch) => {
  try {
    dispatch(fetchPagamentosRequest());
    const { data } = await pagamentosApi.fetchPagamentos(
      page,
      pageSize,
      search
    );
    dispatch(fetchPagamentosSuccess(data));
  } catch (e) {
    dispatch(displayError(e));
  }
};
