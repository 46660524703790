import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Panel from '@/shared/components/Panel';
import moment from 'moment';
import { priceFormatter } from '../../../utils/helpers';
import { BadgePedido } from '../../../shared/components/Badges';

const UltimosPedidos = ({ pedidos }) => {
  return (
    <Panel
      xl={6}
      lg={12}
      md={12}
      title={'Últimos Pedidos'}
      subhead="Últimos 7 pedidos realizados na plataforma"
    >
      <Table responsive striped className="dashboard__table-orders">
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Data do pedido</th>
            <th>Situação</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pedidos &&
            pedidos.map((pedido, index) => (
              <tr key={index}>
                <td>{pedido.usuario?.nome}</td>
                <td>{moment(pedido.createdAt).format('DD/MM/YY HH:mm')}</td>
                <td>
                  <BadgePedido status={pedido.status} />
                </td>
                <td>{priceFormatter(pedido.totalPedido)}</td>
                <td>
                  <Link
                    color="primary"
                    className="icon-btn success"
                    type="button"
                    to={`/pedido/${pedido._id}`}
                  >
                    <EyeIcon />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Link to="/pedidos" className="dashboard__table-orders-link">
        Todos os pedidos <ChevronDownIcon />
      </Link>
    </Panel>
  );
};

export default UltimosPedidos;
