/* eslint-disable import/no-anonymous-default-export */
import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  fetchIngressos: (page, pageSize) =>
    get(
      `${API}/admin/ingressos/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchIngressos: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/ingressos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  getIngresso: (id) => get(`${API}/admin/ingresso/${id}/${getUserId()}`),
  reenviarIngresso: (id) =>
    put(`${API}/admin/ingresso/reenviar/${id}/${getUserId()}`),
  cancelarIngresso: (id) =>
    put(`${API}/admin/ingresso/cancelar/${id}/${getUserId()}`),
  putCheckinIngresso: (id) =>
    put(`${API}/admin/ingresso/checkin/${id}/${getUserId()}`),
  putCheckoutIngresso: (id) =>
    put(`${API}/admin/ingresso/checkout/${id}/${getUserId()}`),
  putReativarIngresso: (id) =>
    put(`${API}/admin/ingresso/reativar/${id}/${getUserId()}`),
};
