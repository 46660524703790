import React from 'react';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import { BadgePagamento } from '../../../shared/components/Badges';
import { priceFormatter, translatePaymentType } from '../../../utils/helpers';
import moment from 'moment';
moment.locale('pt-br');

const formatDetail = (detail = '') => {
  let formatted = detail.replace(/([A-Z][a-z]*)/g, ' $1');
  return formatted.charAt(0).toUpperCase() + formatted.substring(1);
};

const PagamentoDetalheModal = ({ pagamento, ...props }) => {
  if (!pagamento) return <></>;

  return (
    <Modal size="lg" {...props} contentClassName="pt-3 pb-4">
      <ModalHeader tag="div" className="text-left" toggle={props.toggle}>
        <h1>Pagamento</h1>
        <p className="m-0 text-muted">#{pagamento._id}</p>
        <p className="m-0 text-muted">
          {moment(pagamento.createdAt).format('LL - LT')}
        </p>
      </ModalHeader>
      <ModalBody className="px-0 text-left">
        <Row>
          <Col xs={12} md={6} className="border-right">
            <Container>
              <Row className="mb-2">
                <Col xs={4}>
                  <p className="font-weight-bold">Cielo ID</p>
                </Col>
                <Col xs={8}>
                  <p>{pagamento.log.payment.paymentId}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4}>
                  <p className="font-weight-bold">Mensagem</p>
                </Col>
                <Col xs={8}>
                  <p>{`${pagamento.log.payment.returnMessage} (${pagamento.log.payment.returnCode})`}</p>
                </Col>
                <Col xs={4}>
                  <p className="font-weight-bold">Status</p>
                </Col>
                <Col xs={8}>
                  <BadgePagamento status={pagamento.status} />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <p className="font-weight-bold">Valor</p>
                </Col>
                <Col xs={8}>
                  <p>{priceFormatter(pagamento.valor)}</p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} md={6}>
            <Container>
              <Row>
                <Col xs={12}>
                  <h3 className="h6 font-weight-bold">
                    {translatePaymentType(pagamento)}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="font-weight-bold text-muted">Detalhes</p>
                </Col>
              </Row>
              {Object.entries(
                pagamento.log.payment[
                  pagamento.log.payment.type.charAt(0).toLowerCase() +
                    pagamento.log.payment.type.substring(1)
                ] || {}
              ).map((detail, i) => (
                <Row key={`detail-row-${i}-${detail[0]}`} className="pb-2">
                  <Col xs={4}>
                    <p className="font-weight-bold">
                      {formatDetail(detail[0])}
                    </p>
                  </Col>
                  <Col xs={8}>
                    <p style={{ overflowWrap: 'break-word' }}>
                      {detail[1].toString() || '\u2014'}
                    </p>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

// PagamentoDetalheModal.propTypes = {};
// PagamentoDetalheModal.defaultProps = {};

export default PagamentoDetalheModal;
